import * as React from 'react'
import ReactDOM from 'react-dom'

import styled from 'theme/styled-components'
import Provider from 'theme/Provider'

import FocusLock from 'react-focus-lock'
import useEscKeyPressed from 'utils/useEscKeyPressed'

interface Props {
  Content: () => JSX.Element
  justify?: 'flex-start' | 'center' | 'flex-end'
}

const Modal = ({ Content, justify = 'center' }: Props) => {
  useEscKeyPressed(ModalPortal.close)

  return (
    <MainContainer justify={justify}>
      <Back />
      <JustifiedContainer justify={justify}>
        <FocusLock autoFocus={true} returnFocus>
          <Content />
        </FocusLock>
      </JustifiedContainer>
    </MainContainer>
  )
}

const MainContainer = styled('div')<{ justify: 'flex-start' | 'center' | 'flex-end' }>`
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: ${(props) => props.justify};
  opacity: 1;

  -webkit-animation: fadeIn 0.2s linear;
  animation: fadeIn 0.2s linear;
  z-index: 1;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Back = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.colors.backgroundModal};
  border: 0px;
`

const JustifiedContainer = styled('div')<{ justify: 'flex-start' | 'center' | 'flex-end' }>`
  padding: ${(props) => (props.justify !== 'center' ? 64 : 0)}px 0px;
`

let modalRoot: HTMLElement | null

const ModalPortal = {
  open: (props: Props) => {
    modalRoot = document.getElementById('portal_root')

    if (modalRoot) {
      ReactDOM.render(
        <Provider>
          <Modal {...props} />
        </Provider>,
        modalRoot
      )
    }
  },
  close: () => {
    if (modalRoot) {
      ReactDOM.unmountComponentAtNode(modalRoot)
    }
  },
}

export default ModalPortal
