import React from 'react'

import Drawer from './Drawer'
import ThemeSelector from 'theme/ThemeSelector'

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Screens from './Screens'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as UserStore from 'store/user/user'
import * as SiteStore from 'site/store'
import SiteApi from 'site/api'
import * as CGUStore from 'cgu/store'
import * as I18nStore from 'i18n/i18n'

import ScrollToTop from './ScrollToTop'

import CGUScreen from 'cgu/CGUScreen'
import AccessibilityScreen from 'accessibility/AccessibilityScreen'

import { extractDeeplink, extractSearch } from './utils'

interface LayoutProps {
  prefix: string
  lang: string
  logged: boolean
}

const ScreenComponent = ({ children, search }: { children: React.ReactElement; search: { [key: string]: string } }) => {
  const sites = useReducer(SiteStore.store, (s) => s.sites)

  React.useEffect(() => {
    if (search.siteId) {
      // if search contain site id, switch to corresponding site
      const found = sites.find((s) => `${s.id}` === search.siteId)

      if (found) {
        SiteStore.actions.setSite(found)
      }
    }
  }, [search.siteId, sites])

  return children
}

const Layout = ({ lang, prefix, logged }: LayoutProps) => {
  React.useEffect(() => {
    I18nStore.actions.setLang(lang)
    document.documentElement.lang = lang
  }, [lang])

  return (
    <>
      <ThemeSelector />
      <Switch>
        {Object.values(Screens)
          .filter((s) => s.auth === logged)
          .map((s) => {
            const Component = s.screen

            return (
              <Route path={prefix + s.path} exact key={s.name}>
                {({ match, history, location }) => {
                  const m = match || { params: {} }
                  const search = extractSearch(location.search)

                  return (
                    <ScreenComponent search={search}>
                      <Component screen={s.name} {...m.params} {...history.location.state} {...search} />
                    </ScreenComponent>
                  )
                }}
              </Route>
            )
          })}

        <Redirect to={`${prefix}${logged ? '' : '/login'}`} />
      </Switch>
    </>
  )
}

const MainLayout = () => {
  const i18n = useI18n()

  const user = useReducer(UserStore.store, (s) => s.user)
  const site = useReducer(SiteStore.store, (s) => s.site)
  const cgu = useReducer(CGUStore.store, (s) => s.accepted)

  const logged = React.useMemo(() => !!user && !!site && !!cgu.find((c: string) => c === user.id), [user, site, cgu])

  React.useEffect(() => {
    if (site && !SiteStore.loadedSite.get()) {
      // Mettre à jour le site dans le store
      SiteApi.all(i18n.lang)
        .then((res) => res.sites?.find((s) => s.code === site.code))
        .then((s) => {
          if (s) {
            SiteStore.actions.setSite(s)
          }
        })
    }
  }, [site, i18n.lang])

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Drawer hidden={!logged}>
          <Route path="/:lang">
            {({ match, location }) => {
              const lang = match && match.params && match.params.lang

              // deeplink
              if (location.pathname.includes('fonctionnalite')) {
                return <Redirect to={`/${i18n.lang}${extractDeeplink(location.search)}`} />
              }

              // path or search
              if (!match) {
                return <Redirect to={`/${i18n.lang}`} />
              }

              if (!user) {
                if (location.pathname === `/${lang}/cgu`) {
                  return <CGUScreen />
                } else if (location.pathname === `/${lang}/accessibility`) {
                  return <AccessibilityScreen />
                } else {
                  const loginPath = `/${lang}/login`
                  if (location.pathname !== loginPath) {
                    return <Redirect to={loginPath} />
                  }
                }
              } else if (!site) {
                const sitePath = `/${lang}/site`
                if (location.pathname !== sitePath) {
                  return <Redirect to={sitePath} />
                }
              }

              // invalid language
              if (!lang || !i18n.supportedLang.includes(lang)) {
                return <Redirect to={`/${i18n.lang}${location.pathname}${location.search}`} />
              }

              return <Layout lang={lang} prefix={match.url} logged={logged} />
            }}
          </Route>
        </Drawer>
      </ScrollToTop>
    </BrowserRouter>
  )
}

export default MainLayout
