import ws from 'utils/Webservice'

const api = {
  all: (siteId: number) => {
    return ws<ReferentielList>(`/contrib/referentiels?siteId=${siteId}`, 'GET')
  },
  categories: (siteId: number) => {
    return ws<TypeList>(`/contrib/referentiels/types?siteId=${siteId}`, 'GET')
  },
}

export default api
