import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import IconButton from 'components/icons/IconButton'
import Icon from 'components/icons/Icon'
interface Props {
  value: string
  onChange: (search: string) => void
  idSearchInput?: string
  autofocus?: boolean
  placeholder?: string
}

const SearchBar = ({ value, onChange, idSearchInput, autofocus = false, placeholder }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  return (
    <SearchContainer role="search">
      <SearchBarInput
        value={value}
        id={idSearchInput}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder || i18n.t('screens.phonebook.searchPlaceholder')}
        autoFocus={autofocus}
        title={i18n.t('accessibility.titleInputText.searchBar')}
        type="text"
      />
      {value.length > 0 && (
        <IconContainer>
          <IconButton
            name="cross"
            size={17}
            color={Theme.colors.iconicGreyLighter}
            onClick={() => onChange('')}
            ariaLabel={i18n.t('accessibility.ariaLabels.deleteSearchInput')}
          />
        </IconContainer>
      )}
      <SearchIconContainer>
        <Icon name="search" size={15} color={Theme.colors.iconicGreyLighter} />
      </SearchIconContainer>
    </SearchContainer>
  )
}

// CONTAINERS

const SearchContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.theme.colors.background};
  height: 38px;
  border-radius: 52px;
  border: 1px solid ${(props) => props.theme.colors.searchBar};
`
const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`
const SearchIconContainer = styled('div')`
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`

// AUTRES

const SearchBarInput = styled('input')`
  flex: 1;
  ${(props) => props.theme.fonts.subtitle}
  margin-left: 24px;
  height: 36px;
  border: 0;
  outline: 0;
  ::placeholder {
    color: ${(props) => props.theme.colors.searchBar};
  }
`

export default SearchBar
