import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'

import { breakpoints } from 'utils/breakpoints'
import { FormValues } from 'meeting/catering/CateringForm'

interface PickerProps {
  onClick?: () => void
}

interface InputProps {
  min?: number
  max?: number
  type?: React.HTMLInputTypeAttribute
  onChange?: (e: React.FocusEvent<any>) => void
  onBlur?: (e: React.FocusEvent<any>) => void
}

interface Props extends PickerProps, InputProps {
  fieldType: 'picker' | 'smallInput' | 'bigInput' | 'text'
  inputId: keyof FormValues | 'time'
  title: string
  value?: string | number
  placeholder?: string
  error?: string
  disabled?: boolean
  disabledStyle?: boolean
}

const FormField = ({
  fieldType,
  title,
  placeholder,
  onClick,
  value,
  inputId,
  disabled,
  disabledStyle,
  error,
  min,
  max,
  type,
  onChange,
  onBlur,
}: Props) => {
  const [theme] = useTheme()

  if (fieldType === 'picker') {
    return (
      <FieldContainer>
        <FieldTitle htmlFor={inputId}>{title}</FieldTitle>
        <PickerFieldInputButton
          type="button"
          onClick={() => !disabled && !!onClick && onClick()}
          disabled={disabled}
          disabledStyle={disabledStyle}>
          <PickerFieldInputText disabled={disabledStyle}>{value || placeholder}</PickerFieldInputText>
          {!value && (
            <Icon
              name="chevron_bottom"
              color={disabledStyle ? theme.colors.middleLightGrey : theme.colors.primaryDark}
              size={17}
            />
          )}
        </PickerFieldInputButton>
        {error && !disabled && <FieldErrorText>{error}</FieldErrorText>}
      </FieldContainer>
    )
  }

  const inputProps = { onChange, onBlur, disabled }

  if (fieldType === 'smallInput') {
    return (
      <FieldContainer>
        <FieldTitle htmlFor={inputId}>{title}</FieldTitle>
        <SmallInput
          value={value}
          id={inputId}
          type={type}
          onFocus={(e) => e.target.select()}
          min={min}
          max={max}
          error={!!error}
          {...inputProps}
        />
        {error && <FieldErrorText>{error}</FieldErrorText>}
      </FieldContainer>
    )
  }

  if (fieldType === 'bigInput') {
    return (
      <FieldContainer>
        <FieldTitle htmlFor={inputId}>{title}</FieldTitle>
        <BigInput value={value} id={inputId} placeholder={placeholder} {...inputProps} />
      </FieldContainer>
    )
  }

  return (
    <FieldContainer>
      <FieldTitle htmlFor={inputId}>{title}</FieldTitle>
      <PriceFieldContent id={inputId}>{value}</PriceFieldContent>
    </FieldContainer>
  )
}

export default FormField

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const FieldTitle = styled.label`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.mauve};
  margin: 0;
`

const PickerFieldInputButton = styled.button<{ disabledStyle?: boolean }>`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: ${(props) => (props.disabledStyle ? 'not-allowed' : props.disabled ? 'auto' : 'pointer')};
`

const PickerFieldInputText = styled.p<{ disabled?: boolean }>`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => (props.disabled ? props.theme.colors.middleLightGrey : props.theme.colors.primaryDark)};
  margin: 0;
  text-align: left;
`

const SmallInput = styled.input<{ error?: boolean }>`
  ${(props) => props.theme.fonts.bodyBold};
  width: 90px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.contentBackground};
  border: 1px solid ${(props) => (props.error ? props.theme.colors.error : props.theme.colors.middleLightGrey)};
  border-radius: 8px;
  padding: 10px;
  text-transform: uppercase;
`

const BigInput = styled.textarea`
  ${(props) => props.theme.fonts.labelBold};
  width: 340px;
  height: 100px;
  background-color: ${(props) => props.theme.colors.contentBackground};
  border: 1px solid ${(props) => props.theme.colors.middleLightGrey};
  border-radius: 8px;
  padding: 12px;
  resize: none;
  ::placeholder {
    ${(props) => props.theme.fonts.label};
    color: ${(props) => props.theme.colors.unavailable};
  }
  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: calc(100vw - 100px);
  }
`

const FieldErrorText = styled.p`
  ${(props) => props.theme.fonts.labelBold};
  color: ${(props) => props.theme.colors.error};
  margin: 0;
`

const PriceFieldContent = styled.p`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.primaryDark};
  margin: 0;
`
