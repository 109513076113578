import * as React from 'react'
import styled from 'styled-components'

import useReducer from 'store/useReducer'
import * as TransportStore from './store'
import * as SitesStore from 'site/store'
import DirectionItem from './DirectionItem'

import useI18n from 'i18n/useI18n'

interface Props {
  displayedStations: TransportStation[] | undefined
}

const FavoriteTransports = ({ displayedStations }: Props) => {
  const i18n = useI18n()

  const site = useReducer(SitesStore.store, (s) => s.site)
  const favorites = useReducer(TransportStore.store, (s) => s.favorites).filter((fav) => fav.siteId === site?.id)

  const favoriteStations = Array.from(new Set(favorites.map((f) => f.station)))

  const isAvailableFavorite = React.useMemo(
    () =>
      favorites.filter(
        (fav) =>
          !!displayedStations?.find(
            (s) => s.name === fav.station && s.line === fav.line && s.schedules[0].direction === fav.direction
          )
      ),
    [favorites, displayedStations]
  )

  const renderFavoriteContent = () => {
    if (!displayedStations || displayedStations?.length < 1 || favoriteStations.length < 1) {
      // Aucun transport favori
      return <MessageText>{i18n.t('screens.transport.errors.noFavorite')}</MessageText>
    }
    if (isAvailableFavorite.length < 1) {
      // Aucun horaire de transport favori n'est disponible
      return <MessageText>{i18n.t('screens.transport.errors.noFavoriteSchedule')}</MessageText>
    }
    return <ListContainer>{favoriteStations.map(renderFavoriteStation)}</ListContainer>
  }

  const getLineDetails = (fav: FavoriteDirection) => {
    // On retrouve les infos de la ligne dans la liste des stations disponibles
    const { station, line, direction, category } = fav
    return displayedStations?.find(
      (s) => s.name === station && s.line === line && s.category === category && s.schedules[0].direction == direction
    )
  }

  const renderFavoriteStation = (stationName: string) => {
    const items = favorites.filter((fav) => fav.siteId === site?.id && fav.station === stationName)
    const linesDetails = items.map((item) => getLineDetails(item))

    // On n'affiche le nom de la station que s'il existe au moins une ligne disponible
    if (!!linesDetails && !!linesDetails[0]) {
      return (
        <li key={stationName}>
          <React.Fragment>
            <StationName>{stationName}</StationName>
            <LinesContainer>{linesDetails.map(renderFavoriteLine)}</LinesContainer>
          </React.Fragment>
        </li>
      )
    }
  }

  const renderFavoriteLine = (lineDetails: TransportStation | undefined, index: number) => {
    if (!!lineDetails) {
      return (
        <li key={index}>
          <FavoriteContainer>
            <DirectionItem lineDetails={lineDetails} index={index} />
          </FavoriteContainer>
        </li>
      )
    }
  }

  return (
    <>
      <DrawerTitle>
        <label>{i18n.t('screens.transport.favorites')}</label>
      </DrawerTitle>
      {renderFavoriteContent()}
    </>
  )
}

export default FavoriteTransports

// CONTAINERS

const FavoriteContainer = styled('div')`
  padding: 0px 5px;
`

// LISTS

const ListContainer = styled('ol')`
  overflow-y: scroll;
  list-style: none;
  padding: 0;
`

const LinesContainer = styled('ol')`
  list-style: none;
  align-items: center;
  margin-top: 1px;
  padding: 4px 10px;
`

// TEXTES

const DrawerTitle = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px 0px 36px 22px;
`

const StationName = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.iconicGreyLighter};
  text-transform: capitalize;
  padding: 6px 19px;
  margin: 0px;
  border-top: 1px solid ${(props) => props.theme.colors.middleLightGrey};
  border-bottom: 1px solid ${(props) => props.theme.colors.middleLightGrey};
`

const MessageText = styled('p')`
  ${(props) => props.theme.fonts.body};
  text-align: center;
  margin: 0px 22px;
  padding: 10px 0px;
`
