import * as React from 'react'
import Provider from 'theme/Provider'
import styled from 'theme/styled-components'

import Button from 'components/button/Button'

import { breakpoints } from 'utils/breakpoints'
import useEscKeyPressed from 'utils/useEscKeyPressed'

import useI18n from 'i18n/useI18n'
import ReactDOM from 'react-dom'
import FocusLock from 'react-focus-lock'

interface AlertButton {
  label: string
  onClick: () => void
  style?: ButtonStyle
}

interface Props {
  title: string
  description: string
  buttons?: AlertButton[]
}

const WIDTH = 500

const Alert = ({ title, description, buttons }: Props) => {
  const i18n = useI18n()

  useEscKeyPressed(AlertPortal.close)

  const renderButton = (item: AlertButton) => (
    <ButtonContainer key={item.label}>
      <Button label={item.label} onClick={item.onClick} style={item.style} />
    </ButtonContainer>
  )

  return (
    <MainContainer>
      <Back />

      <FocusLock autoFocus={true} returnFocus>
        <Container id="AlertDialog" role="dialog" aria-modal="true" aria-labelledby="modalAlertHeading" tabIndex={-1}>
          <Title id="modalAlertHeading">{title}</Title>
          <Description>{description}</Description>

          <ButtonsContainer>
            {buttons
              ? buttons.map(renderButton)
              : renderButton({ label: i18n.t('common.ok'), onClick: AlertPortal.close })}
          </ButtonsContainer>
        </Container>
      </FocusLock>
    </MainContainer>
  )
}

const MainContainer = styled('div')`
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  opacity: 1;

  -webkit-animation: fadeIn 0.3s linear;
  animation: fadeIn 0.3s linear;
  z-index: 1;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Back = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.colors.backgroundModal};
  border: 0px;
`

const Container = styled('div')`
  border-radius: 12px;
  align-items: center;
  width: ${WIDTH}px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  padding: 40px 32px;

  @media only screen and (max-width: ${breakpoints.small}px) {
    width: 200px;
  }
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h2Bold};
  margin: 0px 0px 36px;
  text-align: center;
`

const Description = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primaryText};
  margin: 0px 0px 30px;
`

const ButtonsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  gap: 17px;
`

const ButtonContainer = styled('div')``

let modalRoot: HTMLElement | null

const AlertPortal = {
  open: (props: Props) => {
    modalRoot = document.getElementById('alert_root')

    if (modalRoot) {
      ReactDOM.render(
        <Provider>
          <Alert {...props} />
        </Provider>,
        modalRoot
      )
    }
  },
  close: () => {
    if (modalRoot) {
      ReactDOM.unmountComponentAtNode(modalRoot)
    }
  },
}

export default AlertPortal
