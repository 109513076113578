import * as React from 'react'
import styled from 'theme/styled-components'

import Main from 'components/main/Main'
import Loader from 'components/status/Loader'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Animator from 'components/animation/Animator'
import SurveyItem from './SurveyItem'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as SitesStore from 'site/store'
import * as SurveyStore from 'survey/store'

import api from './api'

import { breakpoints } from 'utils/breakpoints'
import { filterSurveys } from './utils'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

const renderItem = (item: SurveyWS) => <SurveyItem key={item.id} item={item} />

const SurveyScreen = () => {
  const i18n = useI18n()

  const site = useReducer(SitesStore.store, (s) => s.site)
  const surveys = useReducer(SurveyStore.store, (s) => s.surveys)

  const [status, setStatus] = React.useState<ScreenStatus>('loading')

  React.useEffect(() => {
    if (!!site) {
      analytics.event(values.eventName.functionnality, {
        ecran: values.screens.survey,
        site: site.name,
        type_utilisateur: analytics.typeUser(),
      })
    }
  }, [site])

  React.useEffect(() => {
    if (!!site) {
      api
        .surveysList(site.id)
        .then((res) => {
          SurveyStore.actions.setSurveys(filterSurveys(res.surveys))
          setStatus('ok')
        })
        .catch(() => setStatus('error'))
    }
  }, [site, i18n.lang])

  if (status === 'loading') {
    return <Loader />
  }

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={i18n.t('screens.survey.title')} />

        <Header>
          <Title>{i18n.t('screens.survey.title')}</Title>
        </Header>

        {status === 'error' ? (
          <ErrorContainer>
            <Error>{i18n.t('common.errorDescription')}</Error>
          </ErrorContainer>
        ) : surveys.length === 0 ? (
          <ErrorContainer>
            <Error>{i18n.t('screens.survey.noSurvey')}</Error>
          </ErrorContainer>
        ) : (
          <Animator>{surveys.map(renderItem)}</Animator>
        )}
      </ScreenContainer>
    </Main>
  )
}

export default SurveyScreen

const ErrorContainer = styled('div')`
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  flex: 1;
`

const Error = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
`

const Title = styled('p')`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.primary};
`

const ScreenContainer = styled('div')`
  display: flex;
  padding: 50px 165px;
  z-index: 0;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const Header = styled('div')`
  flex-direction: row;
  margin-bottom: 30px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
  }
`
