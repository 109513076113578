import * as React from 'react'
import styled from 'theme/styled-components'
import Provider from 'theme/Provider'

import Button from 'components/button/Button'
import { SimpleAnimation } from 'components/animation/Animator'

import { breakpoints } from 'utils/breakpoints'
import useEscKeyPressed from 'utils/useEscKeyPressed'

import useI18n from 'i18n/useI18n'

import ReactDOM from 'react-dom'
import FocusLock from 'react-focus-lock'

interface Props {
  news: ArticleDetailV4
}

const Component = ({ news }: Props) => {
  const i18n = useI18n()

  useEscKeyPressed(Share.close)

  const onCopy = () => {
    navigator.clipboard.writeText(window.location.origin + '/fonctionnalite?page=actuDetail&articleId=' + news.id)
    Share.close()
  }

  return (
    <SimpleAnimation animation="fade">
      <MainContainer>
        <Back />

        <FocusLock autoFocus={true} returnFocus>
          <Container
            id="ShareDialog"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modalShareNewsHeading"
            tabIndex={-1}>
            <Title id="modalShareNewsHeading">{i18n.t('screens.news.share.title')}</Title>
            <Content>
              {i18n.t('screens.news.share.article', {
                name: news.title,
                link: `${window.location.origin}/fonctionnalite?page=actuDetail&articleId=${news.id}`,
              })}
            </Content>

            <Button label={i18n.t('screens.news.share.copy')} onClick={onCopy} />
            <Button label={i18n.t('common.back')} onClick={Share.close} />
          </Container>
        </FocusLock>
      </MainContainer>
    </SimpleAnimation>
  )
}

const MainContainer = styled('div')`
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  opacity: 1;

  -webkit-animation: fadeIn 0.3s linear;
  animation: fadeIn 0.3s linear;
  z-index: 1;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Back = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.colors.backgroundModal};
  border: 0px;
`

const Container = styled('div')`
  width: 600px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  padding: 40px;
  @media only screen and (max-width: ${breakpoints.small}px) {
    width: 240px;
  }
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  font-weight: 700;
  margin: 0px 0px 20px;
`

const Content = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 20px;
  padding: 15px;
  border: 1px solid ${(props) => props.theme.colors.accent};
  border-radius: 2px;
`

let modalRoot: HTMLElement | null

const Share = {
  open: (props: Props) => {
    modalRoot = document.getElementById('portal_root')

    if (modalRoot) {
      ReactDOM.render(
        <Provider>
          <Component {...props} />
        </Provider>,
        modalRoot
      )
    }
  },
  close: () => {
    if (modalRoot) {
      ReactDOM.unmountComponentAtNode(modalRoot)
    }
  },
}

export default Share
