import * as React from 'react'

import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as SitesStore from 'site/store'

import api from 'practicalinformation/api'

import ListItemLink from 'components/list/ListItemLink'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  content: PracticalInformationSimple
  folder: string
  isImageIcon?: boolean
}

const ListItem = ({ content, folder, isImageIcon }: Props) => {
  const i18n = useI18n()
  const site = useReducer(SitesStore.store, (s) => s.site)

  const [contentDetails, setContentDetails] = React.useState<PracticalInformationDetails>()

  React.useEffect(() => {
    api.get(i18n.lang, content.id, content.type).then(setContentDetails)
  }, [content])

  if (!site || !contentDetails) {
    return null
  }

  const onClickWebView = () =>
    analytics.event(
      folder === 'mobility'
        ? values.eventName.mobility
        : folder === 'practicallife'
        ? values.eventName.practicalLife
        : values.eventName.wellbeing,
      {
        vue: contentDetails.title,
        site: site.name,
        type_utilisateur: analytics.typeUser(),
      }
    )

  return (
    <ListItemLink
      imageSource={content.photo || require('core/src/assets/content_default.jpeg').default}
      link={contentDetails.link || contentDetails.pdf}
      title={content.title}
      description={content.description}
      isImageIcon={isImageIcon}
      onClick={onClickWebView}
      key={content.id}
    />
  )
}

export default ListItem
