import * as React from 'react'
import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as SitesStore from 'site/store'

import DirectionItem from './DirectionItem'

const TransportItem = ({ stationName, stationLines }: { stationName: string; stationLines: TransportStation[] }) => {
  const i18n = useI18n()

  const site = useReducer(SitesStore.store, (s) => s.site)

  const renderLine = (lineDetails: TransportStation, index: number) => {
    return <DirectionItem key={index} lineDetails={lineDetails} index={index} />
  }

  return (
    <StationContainer
      key={stationName}
      aria-label={i18n.t(`screens.transports.station`, {
        station: stationName,
        lines: stationLines.map((l) => i18n.t(`screens.transports.lines.${l.name}`)),
      })}>
      <StationTitle>{stationName}</StationTitle>
      <Lines>
        {stationLines.length > 0 && !!site ? (
          stationLines.map(renderLine)
        ) : (
          <NoScheduleText>{i18n.t('screens.transport.errors.noScheduleAvailable')}</NoScheduleText>
        )}
      </Lines>
    </StationContainer>
  )
}

export default TransportItem

// CONTAINERS

const StationContainer = styled('div')`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Lines = styled('div')`
  background-color: ${(props) => props.theme.colors.background};
  display: block;
  width: 90%;
  padding: 10px 18px;
  border-radius: 15px;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  align-items: flex-start;
  text-decoration: none;
`

// TEXTS

const StationTitle = styled('h2')`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.iconicGrey};
`

const NoScheduleText = styled('p')`
  ${(props) => props.theme.fonts.tagTiroir};
  color: ${(props) => props.theme.colors.primary};
  padding: 6px 12px 0px;
  text-align: center;
`
