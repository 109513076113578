import * as React from 'react'
import styled from 'theme/styled-components'

import SafeAtWorkItem from './SafeAtWorkItem'
import ContentItem from 'webview/ContentItem'

import Animator from 'components/animation/Animator'
import Loader from 'components/status/Loader'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main from 'components/main/Main'
import FiltersList from 'components/button/FiltersList'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as SafeAtWorkStore from './store'
import * as SiteStore from 'site/store'

import api from 'practicalinformation/api'

import { breakpoints } from 'utils/breakpoints'

import Logger from 'utils/Logger'
import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

const FILTERS: SafeAtWorkFolder[] = ['safetynews', 'security', 'training']

const SafeAtWorkScreen = () => {
  const i18n = useI18n()

  const infos = useReducer(SafeAtWorkStore.store, (s) => s.infos)
  const site = useReducer(SiteStore.store, (s) => s.site!)

  const [filter, setFilter] = React.useState<SafeAtWorkFolder>(FILTERS[0])
  const [loading, setLoading] = React.useState(false)

  const displayedInfos = React.useMemo(() => infos.filter((n) => n.folder === filter), [infos, filter])

  React.useEffect(() => {
    setLoading(true)

    analytics.event(values.eventName.functionnality, {
      ecran: values.screens.safeAtWork,
      site: site.name,
      type_utilisateur: analytics.typeUser(),
    })

    api
      .all(i18n.lang, site.id, 'SAFE@WORK')
      .then((l) => SafeAtWorkStore.actions.setInfos(l.practicalInformations ? l.practicalInformations : []))
      .catch((err) => {
        Logger.error(err)
        SafeAtWorkStore.actions.reset()
      })
      .finally(() => setLoading(false))
  }, [site])

  const renderItem = (item: PracticalInformationSimple) => {
    // Ici, item peut être une page éditoriale interne ou un lien vers une webview
    if (item.type === 'CONTENT') {
      return <SafeAtWorkItem key={item.id} edito={item} />
    }
    return <ContentItem key={item.id} content={item} folder={item.type} />
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={i18n.t('screens.safeAtWork.title')} />
        <Header>
          <Title>{i18n.t('screens.safeAtWork.title')}</Title>
          <FiltersList screenName="safeAtWork" filters={FILTERS} onClick={(f) => setFilter(f as SafeAtWorkFolder)} />
        </Header>

        {displayedInfos.length === 0 ? (
          <Error>{i18n.t('screens.safeAtWork.empty')}</Error>
        ) : (
          <Animator isList gap={30}>
            {displayedInfos.map(renderItem)}
          </Animator>
        )}
      </ScreenContainer>
    </Main>
  )
}

export default SafeAtWorkScreen

const ScreenContainer = styled('div')`
  display: flex;
  padding: 50px 165px;

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 50px 90px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const Header = styled('div')`
  flex-direction: row;
  margin-bottom: 30px;

  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    flex-direction: column;
  }
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h2Bold};
  color: ${(props) => props.theme.colors.primaryDark};
  flex: 1;
  margin: 0px;
`

const Error = styled('p')`
  ${(props) => props.theme.fonts.body};
  text-align: center;
  margin: 50px;
`
