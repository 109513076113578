import * as React from 'react'
import SVG from 'react-inlinesvg'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import icons from './icons'
import Icon from './Icon'

interface Props {
  name: IconName
  onClick: (() => void) | ((evt: React.MouseEvent | React.KeyboardEvent) => void)
  ariaLabel?: string
  color?: string
  size?: number
  height?: number
  isFloating?: boolean
}

export default ({ name, onClick, ariaLabel, size = 20, color, height, isFloating = false }: Props) => {
  const [Theme] = useTheme()

  const iconSrc = icons[name || 'heart']

  const ButtonComponent = () => (
    <Button aria-label={ariaLabel} size={size} color={color || Theme.colors.primary} onClick={onClick}>
      <SVG
        aria-hidden
        focusable="false"
        src={iconSrc}
        preProcessor={(code) =>
          code
            .replace(/ width=".*?"/g, ` width="${size}"`)
            .replace(/height=".*?"/g, `height="${height ? height : size}"`)
        }
      />
    </Button>
  )

  return isFloating ? (
    <ContainerButton color={color || Theme.colors.primary} aria-label={ariaLabel} size={size} onClick={onClick}>
      <Icon name={name} size={15} color={color || Theme.colors.primary} cursor="pointer" />
    </ContainerButton>
  ) : (
    <IconContainer size={size} color={color || Theme.colors.primary}>
      <ButtonComponent />
    </IconContainer>
  )
}

const ContainerButton = styled('button')<{ color: string; disabled?: boolean; size: number }>`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  margin: 5px;

  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.color};
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`

const Button = styled('button')<{ size: number; color?: string }>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  background-color: transparent;
  border: 0;
  padding: 0;
  ${(props) => !!props.color && `color: ${props.color};`}
  cursor: pointer;
`

const IconContainer = styled('div')<{ size: number; color?: string }>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  ${(props) => !!props.color && `color: ${props.color};`}
`
