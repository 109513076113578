import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Carousel from 'components/image/Carousel'
import Animator from 'components/animation/Animator'
import Loader from 'components/status/Loader'
import Tree from 'components/button/Tree'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main from 'components/main/Main'

import { breakpoints } from 'utils/breakpoints'
import useBreakpoints from 'utils/useBreakpoints'
import useReducer from 'store/useReducer'
import useI18n from 'i18n/useI18n'

import * as SitesStore from 'site/store'

import api from './api'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  id: string
}

const EditoDetailScreen = ({ id }: Props) => {
  const [, bp] = useBreakpoints()
  const [theme] = useTheme()

  const i18n = useI18n()
  const [edito, setEdito] = React.useState<PracticalInformationDetails>()
  const [loading, setLoading] = React.useState(false)

  const site = useReducer(SitesStore.store, (s) => s.site)

  const photos = React.useMemo(
    () =>
      edito?.photos
        ?.filter((p) => !!p.url)
        .sort((a, b) => a.position - b.position)
        .map((p) => ({ key: p.url, url: p.url, description: p.photoDescription })),
    [edito]
  )

  const fetch = () => {
    api
      .get(i18n.lang, id, 'CONTENT')
      .then(setEdito)
      .finally(() => setLoading(false))
  }

  React.useEffect(() => {
    if (!!site) {
      analytics.event(values.eventName.infos, {
        vue: id,
        site: site.name,
        type_utilisateur: analytics.typeUser(),
      })
    }
  }, [])

  React.useEffect(() => {
    setLoading(true)
    fetch()
  }, [id])

  if (loading) {
    return <Loader />
  }

  if (!edito) {
    return null
  }

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={`${i18n.t('screens.practicalinformation.titleDetailPage')}${edito.title}`} />
        <Line>
          <Tree
            urlEnd="practicalinformation"
            previousPageTitle="screens.practicalinformation.title"
            currentPageTitle={edito.title}
          />
        </Line>

        <ContentContainer>
          <Animator>
            <Title>{edito.title}</Title>
            {!!photos && (
              <Carousel
                items={photos}
                height={
                  bp === 'phone' ? 115 : bp === 'small' ? 140 : bp === 'medium' ? 230 : bp === 'mediumBig' ? 320 : 370
                }
                itemWidth={
                  bp === 'phone' ? 250 : bp === 'small' ? 300 : bp === 'medium' ? 500 : bp === 'mediumBig' ? 700 : 800
                }
              />
            )}

            <Content
              dangerouslySetInnerHTML={{
                __html: edito.description
                  .replace(/href/g, "target='_blank' href")
                  .replace(/<a/g, `<a style="color: ${theme.colors.secondary}; text-decoration: none"`),
              }}
            />
          </Animator>
        </ContentContainer>
      </ScreenContainer>
    </Main>
  )
}

export default EditoDetailScreen

const ScreenContainer = styled('div')`
  display: flex;
  padding: 50px 100px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const ContentContainer = styled('div')`
  padding: 40px 0px;
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 20px 0px;
  }
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h1};
  margin: 0px 0px 30px;
`

const Line = styled('div')`
  flex-direction: row;
  flex-wrap: wrap;
`

const Content = styled('div')`
  ${(props) => props.theme.fonts.content};
  color: ${(props) => props.theme.colors.iconicGreyLighter};
  line-height: 25px;
  margin-top: 40px;
`
