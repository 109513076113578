import * as React from 'react'

import IconButton from '../icons/IconButton'

import styled from 'theme/styled-components'
import Theme from 'theme/Theme'

import useI18n from 'i18n/useI18n'

interface Props {
  items: CarouselItem[]
  height?: number
  itemWidth?: number
}

const Item = ({ item, focus, height }: { item: CarouselItem; focus: boolean; height: number }) => {
  return <Image height={height} src={item.url} focus={focus} alt="" />
}

const CarouselComponent = ({ items, height = 400, itemWidth = 800 }: Props) => {
  const slide = React.useRef<HTMLDivElement | null>(null)

  const [current, setCurrent] = React.useState(0)
  const [innerW, setInnerW] = React.useState(0)

  const i18n = useI18n()

  React.useEffect(() => {
    if (slide.current) {
      setInnerW(slide.current.offsetWidth)
    }
  }, [slide, itemWidth])

  return (
    <MainContainer>
      {current > 0 && (
        <ArrowContainer visible={current > 0} left>
          <IconButton
            onClick={() => setCurrent(current - 1)}
            color={Theme.colors.white}
            size={22}
            name="chevron_right"
            ariaLabel={i18n.t('accessibility.ariaLabels.carousel.leftArrow')}
          />
        </ArrowContainer>
      )}

      <Slider ref={slide}>
        <Content
          height={height}
          current={current}
          length={items.length}
          width={items.length * itemWidth}
          itemWidth={itemWidth}
          sliderWidth={innerW}>
          <StyledList>
            {items.map((item, idx) => (
              <li key={`${item.key}_${idx}`}>
                <Item item={item} focus={idx === current} height={height} />
              </li>
            ))}
          </StyledList>
        </Content>
      </Slider>

      {current < items.length - 1 && (
        <ArrowContainer visible={current < items.length - 1}>
          <IconButton
            color={Theme.colors.white}
            size={22}
            name="chevron_right"
            ariaLabel={i18n.t('accessibility.ariaLabels.carousel.rightArrow')}
            onClick={() => setCurrent(current + 1)}
          />
        </ArrowContainer>
      )}
    </MainContainer>
  )
}

export default CarouselComponent

const MainContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
`

const Image = styled('img')<{ height: number; focus: boolean }>`
  height: ${(props) => props.height}px;
  width: 800px;
  object-fit: contain;
  user-select: none;

  opacity: ${(props) => (props.focus ? 1 : 0.5)};
  transform: ${(props) => `scale(${props.focus ? 1 : 0.9})`};
  transition: transform 330ms ease-in-out;
`

const ArrowContainer = styled('div')<{ visible: boolean; left?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  opacity: 0.5;
  cursor: pointer;
  margin: 10px;
  z-index: 5;
  position: absolute;
  ${(props) => (props.left ? 'left: 0' : 'right: 0')};
  background-color: ${(props) => props.theme.colors.primaryDark};
  ${(props) => props.left && 'transform: rotate(180deg)'};

  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.blueCarouselArrowOnFocus};
    opacity: 1;
  }
`

const Slider = styled('div')`
  overflow: hidden;
`

const Content = styled('div')<{
  height: number
  current: number
  width: number
  sliderWidth: number
  length: number
  itemWidth: number
}>`
  display: flex;
  flex-direction: row;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;

  transform: ${(props) =>
    `translateX(-${
      props.current !== props.length - 1
        ? props.current === 0
          ? 0
          : props.current * props.itemWidth - (props.sliderWidth - props.itemWidth) / 2
        : props.width - props.sliderWidth
    }px)`};
  transition: transform 330ms ease-in-out;
`

const StyledList = styled('ul')`
  display: flex;
  list-style: none;
  padding: 0;
`
