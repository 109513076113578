import * as React from 'react'
import styled from 'theme/styled-components'

import Loader from 'components/status/Loader'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main from 'components/main/Main'

import useReducer from 'store/useReducer'
import * as RestaurantStore from './store'
import * as SiteStore from 'site/store'

import api from './api'
import useI18n from 'i18n/useI18n'
import { Link } from 'react-router-dom'

import { breakpoints } from 'utils/breakpoints'
import { startOfWeek, addDays, addSeconds } from 'date-fns'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'
import ImageItem from './ImageItem'

const MIN_WIDTH = 250
const MAX_WIDTH = 400
const GRAPH_HEIGHT = 352
const GRAPH_SCALE = 0.4

const RestaurantsScreen = () => {
  const i18n = useI18n()

  const site = useReducer(SiteStore.store, (s) => s.site!)
  const restaurants = useReducer(RestaurantStore.store, (s) => s.restaurants)

  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setLoading(true)

    analytics.event(values.eventName.functionnality, {
      ecran: values.screens.restaurants,
      site: site.name,
      type_utilisateur: analytics.typeUser(),
    })

    api
      .all(site.id)
      .then(RestaurantStore.actions.setRestaurants)
      .catch(RestaurantStore.actions.resetRestaurants)
      .finally(() => setLoading(false))
  }, [site])

  if (loading) {
    return <Loader />
  }

  const scheduleForToday = (items: WeeklySchedule[] | undefined) => {
    if (!items || !items[0] || !items[0].dailySchedules) {
      return i18n.t('components.openning.closed')
    }

    const today = new Date().getDay().toString()
    const item = items[0].dailySchedules.find((item) => item.day.toString() === today)

    if (!item || !item.openingTime || !item.closingTime) {
      return i18n.t('components.openning.closed')
    }

    const week = startOfWeek(Date.now(), { weekStartsOn: 0 })
    const day = addDays(week, item.day)
    const open = addSeconds(day, item.openingTime)
    const close = addSeconds(day, item.closingTime)

    return i18n.t('screens.restaurant.hoursDetail', { open, close })
  }

  const renderItem = (item: RestaurantV3) => (
    <StyledListItem key={item.id}>
      <ItemLink to={`restaurants/${item.id}`}>
        <ImageItem image={item.image} />
        <ItemTitle>{item.name}</ItemTitle>
        <ItemHours>{scheduleForToday(item.weeklySchedules)}</ItemHours>
        {!!item.crowd && item.crowd.activated && !!item.crowd.url && (
          <GraphWebview src={item.crowd.url} scrolling="no" style={{ transform: `scale(${GRAPH_SCALE})` }} />
        )}
      </ItemLink>
    </StyledListItem>
  )

  const pageTitle = i18n.t(`screens.restaurant.title${site.alternativeFunctionalityDesign ? '_alt' : ''}`)

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={pageTitle} />
        <Header />
        <RestaurantList>{restaurants.map(renderItem)}</RestaurantList>
      </ScreenContainer>
    </Main>
  )
}

export default RestaurantsScreen

const ScreenContainer = styled('div')`
  margin: 40px 124px;

  @media only screen and (max-width: ${breakpoints.big}px) {
    margin: 40px 90px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    margin: 40px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    margin: 40px 35px;
  }
`

const RestaurantList = styled('ul')`
  display: grid;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 40px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(${MIN_WIDTH}px, ${MAX_WIDTH}px));

  @media only screen and (min-width: ${breakpoints.big}px) {
    grid-template-columns: repeat(3, minmax(${MIN_WIDTH}px, ${MAX_WIDTH}px));
  }

  @media only screen and (max-width: ${breakpoints.small}px) {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }
`

const ItemLink = styled(Link)`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  min-width: ${MIN_WIDTH}px;
  max-width: ${MAX_WIDTH}px;
`

const ItemTitle = styled('p')`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.primary};
  margin: 15px 0 0;
`

const ItemHours = styled('p')`
  ${(props) => props.theme.fonts.h3};
  color: ${(props) => props.theme.colors.primary};
  margin: 5px 0;
`

const Header = styled('div')`
  margin-bottom: 30px;
`

const StyledListItem = styled('li')`
  @media only screen and (max-width: ${breakpoints.small}px) {
    display: flex;
    justify-content: center;
  }

  transition: all 0.1s ease-in-out;

  :hover {
    transform: scale(1.02);
  }
`

const GraphWebview = styled('iframe')`
  border: 0;
  height: ${GRAPH_HEIGHT}px;
  width: ${MAX_WIDTH / GRAPH_SCALE}px;
  margin: 0 0 ${-GRAPH_HEIGHT * (1 - GRAPH_SCALE) - 10}px;

  @media only screen and (max-width: ${breakpoints.small}px) {
    width: ${MIN_WIDTH / GRAPH_SCALE}px;
  }

  transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
`
