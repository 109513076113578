import * as React from 'react'
import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'

import { breakpoints } from 'utils/breakpoints'
import { getDateLabel } from './utils'

import { Link } from 'react-router-dom'

interface Props {
  article: ArticleV4
}

const IMAGE_SIZE = 130

const ListItem = ({ article }: Props) => {
  const i18n = useI18n()

  const dateLabel = getDateLabel(article, i18n)

  const photo = article.photo && article.photo.sort((a, b) => a.position - b.position)[0]

  return (
    <ItemLink
      key={article.id}
      to={`/news/${article.id}`}
      aria-label={i18n.t('accessibility.ariaLabels.news.goToNews', { newsTitle: article.title })}>
      <ImageContainer>
        <ItemImage src={photo ? photo.url : require('core/src/assets/news_default.jpeg').default} alt="" />
      </ImageContainer>

      <ItemInfos>
        <ItemTag>{i18n.t(`tags.${article.tag}`)}</ItemTag>
        <ItemResume>{article.title}</ItemResume>
        <ItemDate>{dateLabel}</ItemDate>
      </ItemInfos>
    </ItemLink>
  )
}

export default ListItem

const ItemLink = styled(Link)`
  display: flex;
  flex-direction: row;

  text-decoration: none;

  border-radius: 15px;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  align-items: center;

  @media only screen and (max-width: ${breakpoints.small}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const ImageContainer = styled('div')`
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: ${IMAGE_SIZE}px;
  width: 50%;
  max-width: ${IMAGE_SIZE * 2}px;

  @media only screen and (max-width: ${breakpoints.small}px) {
    height: ${IMAGE_SIZE + 20}px;
    width: 100%;
    max-width: 100%;
  }
`

const ItemImage = styled('img')`
  object-fit: contain;
  user-select: none;
  height: 100%;
  width: 100%;
`

const ItemInfos = styled('div')`
  padding: 10px 24px;
  flex-direction: column;
  align-items: flex-start;

  min-width: 100px;
  flex: 1;
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 15px 20px;
  }
`

const ItemTag = styled('p')`
  ${(props) => props.theme.fonts.subtitleBold};
  font-family: 'Georgia';
  color: ${(props) => props.theme.colors.secondaryText};
  margin: 0px;
`

const ItemResume = styled('h2')`
  ${(props) => props.theme.fonts.h3};
  margin: 15px 0px 0px;
`

const ItemDate = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
  margin: 15px 0px 0px;

  color: ${(props) => props.theme.colors.secondary};
`
