import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Animator, { SimpleAnimation } from 'components/animation/Animator'

import Loader from 'components/status/Loader'
import ButtonIconLabel from 'components/button/ButtonIconLabel'
import Tree from 'components/button/Tree'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main from 'components/main/Main'
import Icon from 'components/icons/Icon'

import useI18n from 'i18n/useI18n'

import api from './api'

import { breakpoints } from 'utils/breakpoints'
import { capitalize } from 'utils/stringUtils'

import useReducer from 'store/useReducer'
import * as SiteStore from 'site/store'

import { startOfWeek, addDays, addSeconds } from 'date-fns'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  id: string
}

const week = startOfWeek(Date.now(), { weekStartsOn: 0 })

const RestaurantsScreen = ({ id }: Props) => {
  const i18n = useI18n()
  const site = useReducer(SiteStore.store, (s) => s.site!)
  const [Theme] = useTheme()

  const [restaurant, setRestaurant] = React.useState<RestaurantV3>()
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setLoading(true)

    analytics.event(values.eventName.restaurants, {
      vue: id,
      site: site.name,
      type_utilisateur: analytics.typeUser(),
    })

    api
      .get(site.id, id)
      .then(setRestaurant)
      .finally(() => setLoading(false))
  }, [])

  if (loading) {
    return <Loader />
  }

  if (!restaurant) {
    return null
  }

  const about =
    !!restaurant.about.type &&
    restaurant.about[restaurant.about.type.toLowerCase() as 'html' | 'webview' | 'application']

  const firstWeeklySchedules =
    restaurant.weeklySchedules && restaurant.weeklySchedules.length > 0 && restaurant.weeklySchedules[0]
  const schedules = !!firstWeeklySchedules && firstWeeklySchedules.dailySchedules
  const comment = !!firstWeeklySchedules && firstWeeklySchedules.comment

  const categories = restaurant.menu.json ? Array.from(new Set(restaurant.menu.json.map((m) => m.category))) : []
  const menuPdf = restaurant.menu.type === 'PDF' && restaurant.menu.pdf

  const renderHTML = (html: string) => (
    <About dangerouslySetInnerHTML={{ __html: html.replace(/href/g, "target='_blank' href") }} />
  )

  const renderLink = (link: string, isMenu = false) => (
    <Link isMenu={isMenu}>
      <StyledLink
        href={link}
        target="_blank"
        rel="noreferrer"
        aria-label={i18n.t(
          `accessibility.ariaLabels.restaurant.${isMenu ? 'seeMenuOnNewPage' : 'seeMoreDetailOnNewPage'}`
        )}>
        <StyledButton
          label={i18n.t(`screens.restaurant.${isMenu ? 'seeMenu' : 'description'}`)}
          iconName={isMenu ? 'pdf' : 'web'}
          iconSize={25}
          onClick={() => null}
        />
      </StyledLink>
    </Link>
  )

  const renderSchedule = (item: DailySchedule) => {
    const day = addDays(week, item.day)

    if (!item.openingTime || !item.closingTime) {
      return (
        <StyledListItem key={item.day}>
          <React.Fragment>
            <Line>
              <Day>{i18n.t('screens.restaurant.day', { day })}</Day>
              <Hours closed>{i18n.t(`components.openning.${item.status?.toLowerCase() || 'closed'}`)}</Hours>
            </Line>
          </React.Fragment>
        </StyledListItem>
      )
    }

    const open = addSeconds(day, item.openingTime)
    const close = addSeconds(day, item.closingTime)

    return (
      <StyledListItem key={item.day}>
        <React.Fragment>
          <Line>
            <Day>{capitalize(i18n.t('screens.restaurant.day', { day }))}</Day>
            <Hours>{i18n.t('screens.restaurant.hours', { open, close })}</Hours>
          </Line>
        </React.Fragment>
      </StyledListItem>
    )
  }

  const renderMeal = (item: string, idx: number) => (
    <React.Fragment key={idx}>
      <Meal>{item}</Meal>
    </React.Fragment>
  )

  const renderMeals = (item: string) => {
    const meals = restaurant.menu.json && restaurant.menu.json.filter((m) => m.category === item)

    if (!meals) {
      return null
    }

    return (
      <React.Fragment key={item}>
        <Category>{item}</Category>
        <Separator />
        {meals
          .filter((m) => m.category === item)
          .map((m) => m.name)
          .map(renderMeal)}
      </React.Fragment>
    )
  }

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={`${i18n.t('screens.restaurant.titleDetailPage')}${restaurant.name}`} />
        <Line>
          <Tree
            urlEnd="restaurants"
            previousPageTitle={`screens.restaurant.title${site.alternativeFunctionalityDesign ? '_alt' : ''}`}
            currentPageTitle={restaurant.name}
          />
        </Line>

        <ContentContainer>
          <MainLine>
            <Content>
              <Animator>
                <ImageAndScheduleContainer>
                  <InfoImage src={restaurant.image} alt="" />
                  {!!schedules && (
                    <ScheduleContainer>
                      <Icon name="agenda" size={40} color={Theme.colors.primaryDark} />
                      <Subtitle>{i18n.t('screens.restaurant.schedule')}</Subtitle>
                      <ScheduleList>
                        {schedules.filter((item) => item.day != 6 && item.day != 7).map(renderSchedule)}
                      </ScheduleList>
                      {!!comment && <Subtitle marginBottom={10}>{renderHTML(comment)}</Subtitle>}
                    </ScheduleContainer>
                  )}
                </ImageAndScheduleContainer>
                <Title>{restaurant.name}</Title>
                <InfosContainer>
                  <Animator>
                    {!!about && <>{restaurant.about.type === 'HTML' ? renderHTML(about) : renderLink(about)}</>}

                    {(!!menuPdf || categories.length > 0) && (
                      <>
                        <Subtitle>{i18n.t('screens.restaurant.menuTitle')}</Subtitle>
                      </>
                    )}

                    {!!menuPdf && renderLink(menuPdf, true)}

                    {categories.length > 0 && (
                      <SimpleAnimation>
                        <Menu>{categories.map(renderMeals)}</Menu>
                      </SimpleAnimation>
                    )}
                  </Animator>
                </InfosContainer>
              </Animator>
            </Content>
          </MainLine>
        </ContentContainer>
      </ScreenContainer>
    </Main>
  )
}

export default RestaurantsScreen

const ScreenContainer = styled('div')`
  display: flex;
  padding: 50px 75px;

  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const ContentContainer = styled('div')`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const MainLine = styled('div')`
  flex-direction: row;
  flex-wrap: wrap;
  @media only screen and (max-width: ${breakpoints.big}px) {
    flex-direction: column;
    align-items: center;
  }
`

const Line = styled('div')`
  flex-direction: row;
  flex-wrap: wrap;
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h1Bold};
  font-weight: 400;
  margin-bottom: 0;
  @media only screen and (max-width: ${breakpoints.small}px) {
    margin: 0 0 15px;
  }
`

const Subtitle = styled('h3')<{ marginBottom?: number }>`
  ${(props) => props.theme.fonts.h3Bold};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 18)}px;
`

const Content = styled('div')`
  flex-direction: column;
  flex: 1;
  margin-right: 80px;
  align-items: center;

  @media only screen and (min-width: ${breakpoints.medium}px) {
    width: 590px;
    flex: 0;
  }
`

const InfoImage = styled('img')`
  border-radius: 12px;
  object-fit: cover;
  height: 365px;
  width: 590px;
  @media only screen and (max-width: ${breakpoints.medium}px) {
    width: 352px;
    height: 200px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    width: 250px;
    height: 175px;
  }
`

const InfosContainer = styled('div')`
  padding: 0 0 20px 0;
`

const About = styled('div')`
  ${(props) => props.theme.fonts.body};
  margin-bottom: 50px;
  white-space: pre-wrap;
`

const ScheduleList = styled('ul')`
  list-style: none;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 0;
  width: 200px;
`

const StyledListItem = styled('li')`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const Separator = styled('div')`
  background-color: ${(props) => props.theme.colors.primaryDark};
  height: 1px;
  margin-bottom: 5px;
`

const Day = styled('p')`
  ${(props) => props.theme.fonts.body};
  flex: 1;
  margin: 10px 0;
`

const Hours = styled('span')<{ closed?: boolean }>`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => (props.closed ? props.theme.colors.primary : props.theme.colors.primaryText)};
  margin: 10px 0;
`

const Link = styled('div')<{ isMenu: boolean }>`
  margin: 20px 0;
`

const Menu = styled('div')`
  flex-direction: column;
  padding: 20px;
  border-radius: 15px;
  background-color: ${(props) => props.theme.colors.menu};

  @media only screen and (max-width: ${breakpoints.big}px) {
    margin: 0;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    margin: 0;
  }
`

const Category = styled('h2')`
  ${(props) => props.theme.fonts.h2Bold};
  margin-bottom: 5px;
  margin-top: 0;
`

const Meal = styled('p')`
  ${(props) => props.theme.fonts.h3};
  margin: 12px 0;
`

const StyledLink = styled('a')`
  display: flex;
  align-self: start;
  text-decoration: none;
`

const StyledButton = styled(ButtonIconLabel)`
  display: flex;
`

const ScheduleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.contentBackground};
  padding: 16px;
  max-height: 365px;
  max-width: 200px;

  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    margin-bottom: 16px;
  }

  @media only screen and (max-width: ${breakpoints.phone}px) {
    max-width: 100%;
  }
`

const ImageAndScheduleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media only screen and (max-width: ${breakpoints.mediumBig}px) {
    flex-direction: column;
  }
`
