import * as React from 'react'
import styled from 'theme/styled-components'
import { breakpoints } from 'utils/breakpoints'

import Main from 'components/main/Main'
import MultipleChoices from 'components/survey/MultipleChoices'
import Notation from 'components/survey/SurveyNotation'
import Button from 'components/button/Button'
import Loader from 'components/status/Loader'
import Alert from 'components/alert/Alert'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Tree from 'components/button/Tree'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as SitesStore from 'site/store'
import * as SurveyStore from './store'

import useNavigation from 'core/src/layout/useNavigation'

import api from './api'
import { isAfter } from 'date-fns'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'
import { areValidAnswers, filterSurveys } from './utils'

const MIN_NOTE_COMMENT = 3
const MAX_CHOICES = 3
const MAX_LENGTH = 250

interface Props {
  id: string
}

const SurveyDetailScreen = ({ id }: Props) => {
  const i18n = useI18n()

  const site = useReducer(SitesStore.store, (s) => s.site)

  const [status, setStatus] = React.useState<ScreenStatus>('loading')
  const [survey, setSurvey] = React.useState<SurveyWS>()

  React.useEffect(() => {
    analytics.event(values.eventName.survey, {
      ecran: values.screens.surveyDetail,
      vue: id,
    })
  }, [])

  React.useEffect(() => {
    if (!!site && !survey) {
      setStatus('loading')
      api
        .surveysList(site.id)
        .then((res) => {
          setSurvey(res.surveys.find((s) => s.id === id))
          SurveyStore.actions.setSurveys(filterSurveys(res.surveys))
          setStatus('ok')
        })
        .catch(() => setStatus('error'))
    }
  }, [id, site, i18n.lang])

  if (!survey || !site) {
    return (
      <Main>
        <ScreenContainer>
          <TitleHelmet title={i18n.t('screens.surveyDetail.title')} />
          <Line>
            <Tree
              urlEnd="survey"
              previousPageTitle="screens.survey.title"
              currentPageTitle={i18n.t('screens.surveyDetail.title')}
            />
          </Line>

          {status === 'loading' ? <Loader /> : <Error>{i18n.t('common.errorDescription')}</Error>}
        </ScreenContainer>
      </Main>
    )
  }

  // Si la date limite du sondage est atteinte
  if (isAfter(new Date(), new Date(survey.endDate))) {
    return (
      <Main>
        <ScreenContainer>
          <TitleHelmet title={`${i18n.t('screens.surveyDetail.titleDetailPage')}${survey.title}`} />
          <Line>
            <Tree urlEnd="survey" previousPageTitle="screens.survey.title" currentPageTitle={survey.title} />
          </Line>

          {status === 'loading' ? <Loader /> : <Error>{i18n.t('screens.surveyDetail.endDatePassed')}</Error>}
        </ScreenContainer>
      </Main>
    )
  }

  return <SurveyDetail survey={survey} site={site} />
}

interface SurveyProps {
  survey: SurveyWS
  site: SiteV4
}

const SurveyDetail = ({ survey, site }: SurveyProps) => {
  const i18n = useI18n()
  const navigation = useNavigation()

  const questions = survey.questions.sort((a, b) => a.position - b.position)

  const isQuiz = React.useMemo(() => !!survey && survey.category === 'QUIZ', [survey])

  const initialAnswers = React.useMemo(
    () =>
      questions.reduce((acc, cur) => {
        acc.push({ questionId: cur.id, type: cur.type, choices: cur.type === 'CHOICE' ? [] : undefined })
        return acc
      }, [] as SurveyAnswerForm[]),
    [questions]
  )

  const [answers, setAnswers] = React.useState<SurveyAnswerForm[]>(initialAnswers)
  const [isAnswering, setIsAnswering] = React.useState(false)
  const [isAnswered, setIsAnswered] = React.useState(false)
  const [comment, setComment] = React.useState('')
  const [checkRules, setCheckRules] = React.useState(false)

  const isCommentEmpty = React.useMemo(
    () => answers.some((answer) => !!answer.note && answer.note <= MIN_NOTE_COMMENT) && comment.length === 0,
    [comment, answers]
  )

  const emptyFields = React.useMemo(
    () =>
      questions.filter((question) => {
        const findAnswer = answers.find((answer) => answer.questionId === question.id)
        if (!!findAnswer && !!findAnswer.choices && findAnswer.choices.length === 0) {
          return true
        }
        if (!!findAnswer && findAnswer.type === 'NOTE' && !findAnswer.note) {
          return true
        }
        if (!!findAnswer && findAnswer.type === 'TEXT' && !findAnswer.text) {
          return true
        }
        return false
      }),
    [questions, answers]
  )

  const quizValidAnswers = React.useMemo(
    () =>
      questions
        .filter((question) => question.type === 'CHOICE')
        .map((question) => ({
          id: question.id,
          validAnswers:
            question.choices
              ?.filter((choice) => !!choice.isRightAnswer)
              .map((choice) => choice.id)
              .sort((a, b) => a.localeCompare(b)) || [],
        })),
    [questions]
  )

  const quizValidQuestions = React.useMemo(
    () =>
      quizValidAnswers.map((question) => {
        const userAnswers = answers.find((q) => q.questionId === question.id)?.choices || []

        return {
          id: question.id,
          isValid: areValidAnswers(question.validAnswers, userAnswers),
        }
      }),
    [quizValidAnswers, answers]
  )

  const quizScore = React.useMemo(() => quizValidQuestions.filter((q) => q.isValid).length, [quizValidQuestions])

  const submit = () => {
    setCheckRules(true)

    if (!isCommentEmpty && emptyFields.length === 0) {
      analytics.event(values.eventName.survey, {
        action: values.actions.submitSurvey,
      })

      setIsAnswering(true)

      const body: SurveyAnswersWS = {
        surveyAnswers: answers,
        comment: comment,
      }

      api
        .answerSurvey(site.id, survey.id, body)
        .then(() => {
          Alert.open({
            title: i18n.t('common.success'),
            description: i18n.t(`screens.surveyDetail.send${isQuiz ? 'Quiz' : 'Survey'}Succeed`, {
              title: survey.title,
            }),
            buttons: [{ label: i18n.t('common.ok'), onClick: Alert.close }],
          })

          if (isQuiz) {
            // Quiz : on reste sur la page de détail pour afficher les réponses du quiz
            setIsAnswered(true)
          } else {
            // Sondage : on est redirigé vers la liste des sondages ou la page d'accueil
            api
              .surveysList(site.id)
              .then(({ surveys }) => {
                if (surveys.length > 0) {
                  navigation.push('/survey')
                } else {
                  navigation.push('/')
                }
              })
              .catch(() => navigation.push('/'))
          }
        })

        .catch(() =>
          Alert.open({
            title: i18n.t('common.warning'),
            description: i18n.t('common.error'),
            buttons: [{ label: i18n.t('common.ok'), onClick: Alert.close }],
          })
        )
        .finally(() => setIsAnswering(false))
    } else {
      Alert.open({
        title: i18n.t('common.warning'),
        description: i18n.t('errors.form.emptyFields'),
        buttons: [{ label: i18n.t('common.ok'), onClick: Alert.close }],
      })
    }
  }

  const onChange = (answer: SurveyAnswerForm) => {
    setAnswers([...answers.filter((ans) => ans.questionId !== answer.questionId), answer])
  }

  const renderItem = (item: QuestionWS, index: number) => {
    const isEmptyField = emptyFields.some((field) => field.id === item.id)
    const isNotValid = !quizValidQuestions.find((q) => q.id === item.id)?.isValid

    return (
      <QuestionContainer key={`question_${item.id}`}>
        <QuestionIndex>{i18n.t('screens.surveyDetail.questionIndex', { index: index + 1 })}</QuestionIndex>
        <QuestionTitle isDescription={!!item.description}>{item.title}</QuestionTitle>
        <DescriptionContainer>
          {!!item.description && (
            <QuestionDescription
              dangerouslySetInnerHTML={{
                __html: item.description,
              }}
            />
          )}
        </DescriptionContainer>
        {renderQuestion(item)}
        {checkRules && isEmptyField && <Error>{i18n.t('screens.surveyDetail.requiredField')}</Error>}
        {isAnswered && !!item.quizAdditionalExplanation && (
          <ExplanationContainer isValid={!isNotValid}>
            <ExplanationLabel
              dangerouslySetInnerHTML={{
                __html: item.quizAdditionalExplanation,
              }}
            />
          </ExplanationContainer>
        )}
        <Separator />
      </QuestionContainer>
    )
  }

  const renderQuestion = (question: QuestionWS) => {
    const findAnswer = answers.find((a) => a.questionId === question.id)
    if (!findAnswer) {
      return null
    }
    switch (question.type) {
      case 'CHOICE':
        return (
          <MultipleChoices
            choices={question.choices || []}
            multipleChoices={question.multiple}
            onChange={(choices) => onChange({ questionId: question.id, choices, type: question.type })}
            editable={!isAnswering}
            isAnswered={isAnswered}
            maxChoices={MAX_CHOICES}
            selectedIDs={findAnswer.choices || []}
          />
        )
      case 'NOTE':
        return (
          <Notation
            onChange={(note) => onChange({ questionId: question.id, note, type: question.type })}
            editable={!isAnswering}
            note={findAnswer.note}
          />
        )
      case 'TEXT':
        return (
          <QuestionInput
            onChange={(e) => onChange({ questionId: question.id, text: e.target.value, type: question.type })}
            disabled={isAnswering}
            maxLength={MAX_LENGTH}
            placeholder={i18n.t('screens.surveyDetail.commentPlaceholder', { max: MAX_LENGTH })}
          />
        )
      default:
        return null
    }
  }

  return (
    <Main>
      <Container>
        <Line>
          <Tree urlEnd="survey" previousPageTitle="screens.survey.title" currentPageTitle={survey.title} />
        </Line>

        <Title>{survey.title}</Title>

        {!!survey.description && (
          <DescriptionContainer>
            <SurveyDescription
              dangerouslySetInnerHTML={{
                __html: survey.description,
              }}
            />
          </DescriptionContainer>
        )}

        <QuestionsList>{questions.map(renderItem)}</QuestionsList>

        {!isQuiz && (
          <CommentContainer>
            <QuestionTitle>{i18n.t('screens.surveyDetail.commentDescription', { max: MAX_LENGTH })}</QuestionTitle>
            <CommentInput
              onChange={(e) => setComment(e.target.value)}
              disabled={isAnswering}
              maxLength={MAX_LENGTH}
              placeholder={i18n.t('screens.surveyDetail.commentPlaceholder', { max: MAX_LENGTH })}
            />
            {checkRules && isCommentEmpty && <Error>{i18n.t('screens.surveyDetail.requiredComment')}</Error>}
          </CommentContainer>
        )}

        {isAnswered ? (
          <CommentContainer>
            <Title>
              {i18n.t('screens.surveyDetail.score', { score: quizScore, total: quizValidQuestions.length })}
            </Title>
          </CommentContainer>
        ) : (
          <Button disabled={isAnswering} label={i18n.t('common.validate')} onClick={submit} loading={isAnswering} />
        )}
      </Container>
    </Main>
  )
}

export default SurveyDetailScreen

const Container = styled('div')`
  display: flex;
  padding: 50px 165px;
  z-index: 0;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const QuestionsList = styled('div')`
  padding: 30px 0px 0px;
`

const Title = styled('p')`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.primary};
`

const DescriptionContainer = styled('div')`
  min-height: 12px;
`

const QuestionTitle = styled('p')<{ isDescription?: boolean }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primaryText};
  padding-bottom: ${(props) => (props.isDescription ? 0 : 10)}px;

  margin-top: 0px;
`

const QuestionInput = styled('textarea')`
  ${(props) => props.theme.fonts.body}
  min-height: 60px;
  border: 1px solid ${(props) => props.theme.colors.accent};
  padding: 10px;
  border-radius: 4px;
  color: ${(props) => props.theme.colors.primaryText};
  outline: 0px;
`

const Separator = styled('div')`
  height: 0.5px;
  background-color: ${(props) => props.theme.colors.accent};
  margin: 30px 0px;
`

const CommentContainer = styled('div')`
  margin-bottom: 30px;
`

const CommentInput = styled('textarea')`
  ${(props) => props.theme.fonts.body}
  min-height: 150px;
  margin-top: 15px;
  border: 1px solid ${(props) => props.theme.colors.accent};
  padding: 10px;
  border-radius: 4px;
  color: ${(props) => props.theme.colors.primaryText};
  outline: 0px;
`

const Error = styled('p')`
  padding-top: 4px;
  ${(props) => props.theme.fonts.body};
`

const ScreenContainer = styled('div')`
  display: flex;
  padding: 50px 100px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const Line = styled('div')`
  flex-direction: row;
  flex-wrap: wrap;
`

const SurveyDescription = styled('div')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.iconicGreyLighter};
  line-height: 25px;
  margin-top: 40px;
`

const QuestionDescription = styled('div')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.iconicGreyLighter};
  line-height: 25px;
  margin-top: 40px;
`

const QuestionContainer = styled('div')``

const QuestionIndex = styled('p')`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.positionName};
`

// QUIZ

const ExplanationContainer = styled('div')<{ isValid: boolean }>`
  background-color: ${(props) => (props.isValid ? props.theme.colors.validLight : props.theme.colors.errorLight)};
  border-radius: 4px;
  margin-top: 10px;
  padding: 0px 12px;
`

const ExplanationLabel = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primaryText};
  text-align: center;
  margin: 8px 0px;
`
