import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Carousel from 'components/image/Carousel'
import IconLabel from 'components/label/IconLabel'
import Animator from 'components/animation/Animator'
import Loader from 'components/status/Loader'
import Alert from 'components/alert/Alert'
import Tree from 'components/button/Tree'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Share from './Share'
import Main from 'components/main/Main'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as NewsStore from './store'
import * as SiteStore from 'site/store'

import { breakpoints } from 'utils/breakpoints'
import useBreakpoints from 'utils/useBreakpoints'
import { computeTimeLabel, getDateLabel } from './utils'

import api from './api'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  id: string
}

const NewsDetailScreen = ({ id }: Props) => {
  const i18n = useI18n()
  const [, bp] = useBreakpoints()
  const [Theme] = useTheme()
  const site = useReducer(SiteStore.store, (s) => s.site!)

  const [news, setNews] = React.useState<ArticleDetailV4>()
  const [nbLikes, setNbLikes] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [registering, setRegistering] = React.useState(false)

  const likes = useReducer(NewsStore.store, (s) => s.likes)
  const liked = React.useMemo(() => !!news && likes.includes(news.id), [likes, news])

  const photos = React.useMemo(
    () =>
      news?.photos
        .sort((a, b) => a.position - b.position)
        .map((p) => ({ key: p.url, url: p.url, description: p.photoDescription })),
    [news]
  )

  const fetch = () => {
    api
      .article(id)
      .then((n) => {
        setNews(n)
        setNbLikes(n.nbLikes)
      })
      .finally(() => setLoading(false))
  }

  React.useEffect(() => {
    setLoading(true)
    analytics.event(values.eventName.news, {
      vue: id,
      site: site.name,
      type_utilisateur: analytics.typeUser(),
    })
    fetch()
  }, [id])

  const toggleLike = () => {
    if (news) {
      analytics.event(values.eventName.news, {
        action: liked ? values.actions.unlike : values.actions.like,
        like: id,
        site: site.name,
        type_utilisateur: analytics.typeUser(),
      })
      if (liked) {
        NewsStore.actions.setLikes(likes.filter((l) => l !== news.id))
        api.dislike(site.id, news.id)
        setNbLikes(nbLikes - 1)
      } else {
        NewsStore.actions.setLikes([...likes, news.id])
        api.like(site.id, news.id)
        setNbLikes(nbLikes + 1)
      }
    }
  }

  const register = () => {
    if (!news) {
      return null
    }

    setRegistering(true)

    analytics.event(values.eventName.news, {
      action: values.actions.agenda,
      agenda: id,
      site: site.name,
      type_utilisateur: analytics.typeUser(),
    })

    api
      .register(news)
      .then(() => {
        Alert.open({
          title: i18n.t('screens.news.title'),
          description: i18n.t('screens.news.calendar.succeed'),
        })
      })
      .catch(() => {
        Alert.open({
          title: i18n.t('screens.news.title'),
          description: i18n.t('screens.news.calendar.failed'),
        })
      })
      .finally(() => setRegistering(false))
  }

  const onShare = () => {
    if (news) {
      analytics.event(values.eventName.news, {
        action: values.actions.share,
        partage: id,
        site: site.name,
        type_utilisateur: analytics.typeUser(),
      })
      Share.open({ news })
    }
  }

  if (loading) {
    return <Loader />
  }

  if (!news) {
    return null
  }

  const dateLabel = getDateLabel(news, i18n)
  const time = news.type === 'event' && computeTimeLabel(news, i18n)

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={`${i18n.t('screens.news.titleDetailPage')}${news.title}`} />

        <Line>
          <Tree urlEnd="news" previousPageTitle="screens.news.title" currentPageTitle={news.title} />
        </Line>

        <ContentContainer>
          <Animator>
            <Line>
              <LineDate>
                <IconLabel
                  label={dateLabel}
                  icon="agenda"
                  color={Theme.colors.darkGrey}
                  iconColor={Theme.colors.secondaryText}
                />
                {!!time && (
                  <IconLabel
                    label={time}
                    icon="clock"
                    color={Theme.colors.darkGrey}
                    iconColor={Theme.colors.secondaryText}
                  />
                )}
                {news.type === 'event' && !!news.location && (
                  <IconLabel
                    label={news.location}
                    icon="target"
                    color={Theme.colors.darkGrey}
                    iconColor={Theme.colors.secondaryText}
                  />
                )}
              </LineDate>

              <LineElement>
                <IconLabel
                  label={i18n.t(`screens.news.likes`, { count: nbLikes })}
                  icon="heart"
                  color={Theme.colors.darkGrey}
                  iconColor={Theme.colors.secondaryText}
                />
              </LineElement>
            </Line>

            <Tag>{i18n.t(`tags.${news.tag}`)}</Tag>
            <Title>{news.title}</Title>
            {!!photos && (
              <Carousel
                items={photos}
                height={
                  bp === 'phone' ? 115 : bp === 'small' ? 140 : bp === 'medium' ? 230 : bp === 'mediumBig' ? 320 : 370
                }
                itemWidth={
                  bp === 'phone' ? 250 : bp === 'small' ? 300 : bp === 'medium' ? 500 : bp === 'mediumBig' ? 700 : 800
                }
              />
            )}
            <Line>
              <LineElement>
                <IconLabel
                  label={i18n.t('common.share')}
                  icon="share"
                  onClick={onShare}
                  color={Theme.colors.primary}
                  iconColor={Theme.colors.secondary}
                  focusable
                  ariaLabel={i18n.t(`accessibility.ariaLabels.news.share`)}
                />
              </LineElement>
              {news.type === 'event' && (
                <LineElement>
                  <IconLabel
                    label={i18n.t(`common.register`)}
                    icon="agenda"
                    onClick={register}
                    iconColor={Theme.colors.secondary}
                    color={Theme.colors.primary}
                    disabled={registering}
                    focusable
                  />
                </LineElement>
              )}
              <LineElement>
                <IconLabel
                  label={i18n.t(`screens.news.${liked ? 'dislike' : 'like'}`)}
                  icon={liked ? 'heart_filled' : 'heart'}
                  onClick={toggleLike}
                  iconColor={Theme.colors.secondary}
                  color={Theme.colors.primary}
                  focusable
                  ariaLabel={i18n.t(`accessibility.ariaLabels.news.${liked ? 'unlike' : 'like'}`)}
                />
              </LineElement>
            </Line>

            {!!news.resume && (
              <Head dangerouslySetInnerHTML={{ __html: news.resume.replace(/href/g, "target='_blank' href") }} />
            )}

            {news.source === 'SHAREPOINT' ? (
              <>
                <Content>{i18n.t('screens.news.warning')}</Content>
                <MyHub>
                  <StyledLink
                    href={news.description}
                    target="_blank"
                    rel="noreferrer"
                    aria-label={i18n.t('accessibility.ariaLabels.news.seeNewsMyHub')}>
                    {i18n.t('screens.news.myhub')}
                  </StyledLink>
                </MyHub>
              </>
            ) : (
              <Content
                dangerouslySetInnerHTML={{ __html: news.description.replace(/href/g, "target='_blank' href") }}
              />
            )}
          </Animator>
        </ContentContainer>
      </ScreenContainer>
    </Main>
  )
}

export default NewsDetailScreen

const ScreenContainer = styled('div')`
  display: flex;
  padding: 50px 100px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const ContentContainer = styled('div')`
  padding: 40px 0px;
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 20px 0px;
  }
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h1};
  margin: 0px 0px 30px;
`

const Line = styled('div')`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
`

const LineElement = styled('div')`
  margin: 15px 25px 15px 0px;
`

const LineDate = styled('div')`
  margin: 15px 10px 15px 0px;
  flex: 1;
  flex-direction: column;
`

const Content = styled('p')`
  ${(props) => props.theme.fonts.content};
  text-align: justify;
  color: ${(props) => props.theme.colors.iconicGrey};
  margin: 0px;
`

const Tag = styled('p')`
  ${(props) => props.theme.fonts.subtitleBold};
  font-family: 'Georgia';
  color: ${(props) => props.theme.colors.secondaryText};
  margin: 0px;
`

const Head = styled('h2')`
  ${(props) => props.theme.fonts.h2Bold};
  margin: 20px 20px 20px 0px;
`

const MyHub = styled('div')`
  align-self: center;
  width: 400px;
  margin: 25px;
`

const StyledLink = styled('a')`
  display: flex;
  padding: 15px 20px;
  align-self: center;
  text-decoration: none;
  border-radius: 50px;
  color: ${(props) => props.theme.colors.primaryText};
  background-color: ${(props) => props.theme.colors.accent};
  ${(props) => props.theme.fonts.body};
  text-align: center;
`
