import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'
import Checkbox from 'components/button/Checkbox'

interface Props {
  choices: QuestionChoiceWS[]
  multipleChoices?: boolean
  onChange: (choices: string[]) => void
  editable?: boolean
  isAnswered?: boolean
  maxChoices: number
  selectedIDs: string[]
}

const ICON_SIZE = 24

const MultipleChoices = ({
  choices,
  multipleChoices,
  onChange,
  editable = true,
  isAnswered = false,
  maxChoices,
  selectedIDs,
}: Props) => {
  const [theme] = useTheme()
  const [choicesDisabled, setChoicesDisabled] = React.useState(false)

  React.useEffect(() => {
    setChoicesDisabled(!!multipleChoices && selectedIDs.length >= maxChoices)
  }, [selectedIDs])

  const onPress = (id: string) => {
    if (editable && !isAnswered) {
      if (selectedIDs.includes(id)) {
        onChange(selectedIDs.filter((sID) => sID !== id))
      } else if (multipleChoices) {
        if (!choicesDisabled) {
          onChange([...selectedIDs, id])
        }
      } else {
        onChange([id])
      }
    }
  }

  const renderItem = (item: QuestionChoiceWS) => {
    const itemId = item.id
    const isSelected = selectedIDs.includes(itemId)
    const isDisabled = choicesDisabled && !isSelected

    if (!multipleChoices) {
      return (
        <Button
          key={item.id}
          isDisabled={!editable}
          selected={isSelected}
          onClick={() => onPress(itemId)}
          color={
            isAnswered
              ? item.isRightAnswer
                ? theme.colors.valid
                : isSelected
                ? theme.colors.error
                : theme.colors.background
              : isSelected
              ? theme.colors.secondary
              : theme.colors.background
          }>
          <LabelUniqueChoice selected={isSelected}>{item.text}</LabelUniqueChoice>
        </Button>
      )
    }

    return (
      <ChoiceContainer key={item.id} selected={isSelected} onClick={() => (isDisabled ? undefined : onPress(itemId))}>
        {!isAnswered ? (
          <Checkbox active={isSelected} onClick={() => onPress(itemId)} ariaLabel="" />
        ) : item.isRightAnswer ? (
          <Icon name="check" color={theme.colors.valid} size={ICON_SIZE} />
        ) : isSelected ? (
          <Icon name="cross" color={theme.colors.error} size={ICON_SIZE} />
        ) : (
          <EmptyIcon />
        )}
        <Label isDisabled={isDisabled} isBold={isAnswered && !isSelected && !!item.isRightAnswer}>
          {item.text}
        </Label>
      </ChoiceContainer>
    )
  }

  return <>{choices.map(renderItem)}</>
}

export default MultipleChoices

const Label = styled('p')<{ isDisabled?: boolean; isBold?: boolean }>`
  ${(props) => (props.isBold ? props.theme.fonts.bodyBold : props.theme.fonts.body)};
  color: ${(props) => (props.isDisabled ? props.theme.colors.disable : props.theme.colors.primaryText)};
  padding-left: 8px;
  cursor: ${(props) => (props.isDisabled ? 'auto' : 'pointer')};
`

const LabelUniqueChoice = styled('p')<{ selected: boolean }>`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => (props.selected ? props.theme.colors.white : props.theme.colors.primaryDark)};
  padding-left: 8px;
`

const ChoiceContainer = styled('div')<{ selected: boolean }>`
  align-items: center;
  flex-direction: row;
`

const Button = styled('div')<{ isDisabled?: boolean; selected: boolean; color: string }>`
  justify-content: center;
  align-items: center;
  padding: 7px;
  margin-bottom: 5px;
  background-color: ${(props) => props.color};
  border: 1px solid
    ${(props) =>
      props.color !== props.theme.colors.background ? props.theme.colors.background : props.theme.colors.primaryDark};
  border-radius: 4px;
  cursor: ${(props) => (props.isDisabled ? 'auto' : 'pointer')};
`

const EmptyIcon = styled('div')`
  width: ${ICON_SIZE}px;
`
