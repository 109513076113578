import * as React from 'react'
import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'

import { breakpoints } from 'utils/breakpoints'

interface Props {
  screenName: ScreenName
  filters: string[]
  onClick: (f: string) => void
}

const FiltersList = ({ screenName, filters, onClick }: Props) => {
  const i18n = useI18n()

  const [filter, setFilter] = React.useState(filters[0])

  const renderFilter = (f: string) => {
    const active = filter === f
    const nameFilter = i18n.t(`screens.${screenName}.filter.${f}`)

    return (
      <li key={f}>
        <FilterButton
          onClick={() => {
            onClick(f)
            setFilter(f)
          }}
          aria-label={i18n.t(`accessibility.ariaLabels.${screenName}.filterButton${active ? 'Activated' : ''}`, {
            filter: nameFilter,
          })}>
          <FilterCheck active={active}>{active && <FilterPoint />}</FilterCheck>
          <FilterText active={active}>{nameFilter}</FilterText>
        </FilterButton>
      </li>
    )
  }

  return <Filters>{filters.map(renderFilter)}</Filters>
}

export default FiltersList

const Filters = styled('ul')`
  display: flex;
  list-style: none;
  padding: 0;
  flex-direction: row;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    margin-top: 20px;
    flex-wrap: wrap;
  }
`

const FilterButton = styled('button')`
  margin-right: 30px;

  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.background};
  border: 0px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    margin-bottom: 10px;
  }
`

const FilterCheck = styled('div')<{ active: boolean }>`
  height: 12px;
  width: 12px;
  min-width: 12px; // sans cette ligne, la largeur peut être inférieure si FilterText contient plusieurs lignes de texte
  border-radius: 10px;
  border: 2px solid ${(props) => (props.active ? props.theme.colors.secondary : props.theme.colors.secondaryText)};
  padding: 2px;
`

const FilterPoint = styled('div')`
  flex: 1;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.secondary};
`

const FilterText = styled('p')<{ active: boolean }>`
  ${(props) => (props.active ? props.theme.fonts.bodyBold : props.theme.fonts.body)};
  color: ${(props) => (props.active ? props.theme.colors.secondary : props.theme.colors.secondaryText)};
  margin: 0px 0px 0px 10px;
`
