import * as React from 'react'
import styled from 'theme/styled-components'

import { breakpoints } from 'utils/breakpoints'
import { Link } from 'react-router-dom'
import useI18n from 'i18n/useI18n'

interface Props {
  item: SurveyWS
}

const ListItem = ({ item }: Props) => {
  const i18n = useI18n()
  return (
    <ItemLink key={item.id} to={`survey/${item.id}`}>
      <ItemInfos>
        <Tag>{i18n.t(`screens.survey.tags.${item.category || 'SURVEY'}`)}</Tag>
        <ItemTitle>{item.title}</ItemTitle>
        <ItemResume>{i18n.t('screens.survey.endDate', { date: new Date(item.endDate) })}</ItemResume>
      </ItemInfos>
    </ItemLink>
  )
}

export default ListItem

const ItemLink = styled(Link)`
  display: flex;
  margin-bottom: 30px;
  flex-direction: row;

  border-radius: 15px;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  align-items: center;
  text-decoration: none;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const ItemInfos = styled('div')`
  padding: 20px 30px;
  flex-direction: column;

  min-width: 100px;
  flex: 1;
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 15px 20px;
  }
`

const Tag = styled('p')`
  ${(props) => props.theme.fonts.tagTiroir};
  color: ${(props) => props.theme.colors.primaryText};
  margin: 0px;
`

const ItemTitle = styled('h2')`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.secondary};
  margin: 12px 0px;
`

const ItemResume = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 18px;
  margin: 0px;
`
