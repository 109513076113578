import * as React from 'react'
import styled from 'theme/styled-components'
import { keyframes } from 'styled-components'
import { breakpoints } from 'utils/breakpoints'

const MIN_WIDTH = 250
const MAX_WIDTH = 400

interface Props {
  image: string
}

const ImageItem = ({ image }: Props) => {
  const [loaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    const img = new window.Image()
    img.src = image
    img.onload = () => setTimeout(() => setLoaded(true), 0)
  }, [])

  return <ImageContainer>{loaded ? <ItemImage src={image} alt="" /> : <Skeleton />}</ImageContainer>
}

export default ImageItem

const ImageContainer = styled.div``

const loadingAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`

const Skeleton = styled.div`
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: ${loadingAnimation} 1.5s infinite;
  min-width: ${MIN_WIDTH}px;
  max-width: ${MAX_WIDTH}px;

  height: auto;
  aspect-ratio: 4/3;
  object-fit: cover;
  border-radius: 12px;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: 100%;
  }
`

const ItemImage = styled('img')`
  min-width: ${MIN_WIDTH}px;
  max-width: ${MAX_WIDTH}px;

  height: auto;
  aspect-ratio: 4/3;
  object-fit: cover;
  border-radius: 12px;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: 100%;
  }
`
