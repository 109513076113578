import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import Category from 'components/label/Category'
import SectionHeader from 'components/label/SectionHeader'
import Icon from 'components/icons/Icon'
import Loader from 'components/status/Loader'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as RegisterStore from './store'

import config from 'core/src/config'

import { isBefore } from 'date-fns'

import { Link } from 'react-router-dom'

import { capitalize } from 'utils/stringUtils'

interface Props {
  screenStatus?: ScreenStatus
}

type ReservationType = 'current' | 'future' | 'past'

interface Option {
  title: ReservationType
  data: Booking[]
}

const MyReservationsList = ({ screenStatus = 'ok' }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const reservations = useReducer(RegisterStore.store, (s) => s.reservations)
  const [status, setStatus] = React.useState<ScreenStatus>(screenStatus)
  const [sections, setSections] = React.useState<Option[]>()
  const [pastSection, setPastSection] = React.useState<Option>()
  const [unfoldPast, setUnfoldPast] = React.useState(false)

  React.useEffect(() => {
    setStatus(screenStatus)
  }, [screenStatus])

  React.useEffect(() => {
    if (!!reservations) {
      const past: Option = { title: 'past', data: [] }
      const sorted = reservations
        .sort((a, b) => a.startDate.localeCompare(b.startDate))
        .reduce(
          (acc, cur) => {
            const now = new Date()
            if (isBefore(new Date(cur.endDate), now)) {
              // Réservation passée
              past.data.push(cur)
            } else if (isBefore(now, new Date(cur.startDate))) {
              // Réservation à venir
              acc[1].data.push(cur)
            } else {
              // Réservation en cours
              acc[0].data.push(cur)
            }
            return acc
          },
          [
            { title: 'current', data: [] },
            { title: 'future', data: [] },
          ] as Option[]
        )
      setSections(sorted)
      setPastSection(past)
    }
  }, [reservations])

  const renderItem = (item: Booking, index: number) => {
    const dateLabel = capitalize(
      i18n.t('screens.register.detail.fullDateSlot', {
        start: new Date(item.startDate),
        end: new Date(item.endDate),
      })
    )
    const isCancelled = item.status !== 'BOOKED'
    const isPast = isBefore(new Date(item.endDate), new Date())
    const isReachable = !isCancelled && !isPast

    return (
      <li key={item.animationId + item.id}>
        <Item
          isFirst={index === 0}
          style={{ pointerEvents: isReachable ? 'auto' : 'none', cursor: isReachable ? 'pointer' : 'auto' }}
          to={`/register/${item.animationId}/${item.id}`}
          aria-label={i18n.t('accessibility.ariaLabels.register.goToReservation', { title: item.animationName })}>
          <Picture isCancelled={isCancelled} src={config.SERVER_PREFIX + item.animationImage} />

          <Infos>
            {isCancelled && (
              <ItemStatusContainer>
                <ItemStatus>{i18n.t(`screens.myReservations.status.${item.status}`)}</ItemStatus>
              </ItemStatusContainer>
            )}

            <ItemDate isCancelled={isCancelled}>{dateLabel}</ItemDate>
            <ItemTitle isCancelled={isCancelled}>{item.animationName}</ItemTitle>

            {!!item.animationPlace && (
              <Category
                icon="compass"
                label={item.animationPlace}
                font="label"
                iconSize={16}
                iconColor={theme.colors.iconicGreyLighter}
              />
            )}
          </Infos>

          {isReachable && <Icon name="chevron_right" size={16} color={theme.colors.primaryDark} />}
        </Item>
      </li>
    )
  }

  const getSectionStyle = (type: 'current' | 'future' | 'past') => {
    switch (type) {
      case 'current':
        return {
          borderColor: 'transparent',
          backgroundColor: theme.colors.transparentGreen,
          textColor: theme.colors.available,
          iconColor: theme.colors.available,
          icon: 'point' as IconName,
        }
      case 'future':
        return {
          borderColor: 'transparent',
          backgroundColor: theme.colors.transparentGrey,
          textColor: theme.colors.primaryText,
        }
      case 'past':
        return {
          backgroundColor: theme.colors.background,
          textColor: theme.colors.iconicGreyLighter,
          borderColor: theme.colors.middleLightGrey,
          foldable: true,
          onToggle: setUnfoldPast,
        }
      default:
        return {}
    }
  }

  const renderSection = (section: { title: ReservationType; data: Booking[] }) => {
    if (!section || !section.data || section.data.length === 0) {
      return <></>
    }

    return (
      <SectionContainer key={section.title}>
        <React.Fragment>
          <SectionHeader
            aria-label={i18n.t(`screens.myReservations.sections.${section.title}`)}
            title={i18n.t(`screens.myReservations.sections.${section.title}`)}
            {...getSectionStyle(section.title)}
          />
          {(section.title !== 'past' || unfoldPast) && <List>{section.data.map(renderItem)}</List>}
        </React.Fragment>
      </SectionContainer>
    )
  }

  return (
    <Container>
      <DrawerTitle>
        <label>{i18n.t('screens.myReservations.title')}</label>
      </DrawerTitle>
      {status === 'loading' ? (
        <Loader />
      ) : status === 'error' ? (
        <Empty>{i18n.t('screens.myReservations.error')}</Empty>
      ) : !sections || !reservations || reservations.length === 0 ? (
        <Empty>{i18n.t('screens.myReservations.empty')}</Empty>
      ) : (
        <List>
          {sections.map(renderSection)}
          {!!pastSection && renderSection(pastSection)}
        </List>
      )}
    </Container>
  )
}

export default MyReservationsList

const Container = styled('div')`
  padding-bottom: 40px;
`

const DrawerTitle = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  padding: 0px 24px 14px;
  margin: 0px;
`

// SECTIONS LIST

const List = styled('ul')`
  list-style: none;
  padding: 0px;
  margin: 0px;
`

const SectionContainer = styled('li')`
  padding-top: 16px;
  background-color: ${(props) => props.theme.colors.background};
`

// ITEMS LIST

const Item = styled(Link)<{ isFirst: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  margin: 0px 24px;
  padding: 12px 0px;
  border: 0px solid ${(props) => props.theme.colors.middleLightGrey};
  border-top-width: ${(props) => (props.isFirst ? 0 : 1)}px;
`

const Picture = styled('img')<{ isCancelled: boolean }>`
  align-self: flex-start;
  object-fit: cover;
  height: 70px;
  width: 70px;
  background-color: ${(props) => props.theme.colors.disable};
  opacity: ${(props) => (props.isCancelled ? 0.5 : 1)};
`

const Infos = styled('div')`
  display: flex;
  flex: 1;
  text-align: start;
  flex-direction: column;
  justify-content: center;
  margin-left: 14px;
`

const Text = styled('p')<{ isCancelled: boolean }>`
  margin: 0px;
  padding: 0px;
  opacity: ${(props) => (props.isCancelled ? 0.5 : 1)};
`

const ItemDate = styled(Text)`
  ${(props) => props.theme.fonts.labelBold};
  color: ${(props) => props.theme.colors.coral};
  line-height: 17px;
`

const ItemTitle = styled(Text)`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => props.theme.colors.primaryText};
  margin: 6px 0px 12px;
  line-height: 19px;
`

const ItemStatusContainer = styled('div')`
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  padding: 4px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.middleLightGrey};
`

const ItemStatus = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.primaryText};
  line-height: 14px;
  margin: 0px;
  text-align: center;
`

const Empty = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.accentText};
  text-align: center;
  padding: 24px;
`
