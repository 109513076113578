import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import Button from 'components/button/Button'
import Category from 'components/label/Category'
import SectionHeader from 'components/label/SectionHeader'
import Icon from 'components/icons/Icon'
import Loader from 'components/status/Loader'

import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as CateringStore from './store'
import * as SiteStore from 'site/store'

import { isBefore } from 'date-fns'

import { Link } from 'react-router-dom'

import Logger from 'utils/Logger'
import { capitalize } from 'utils/stringUtils'
import { formatDate } from './utils'

import api from './api'

interface Option {
  title: string
  data: InstantSimplified[]
}

const MyInstantsList = () => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const sites = useReducer(SiteStore.store, (s) => s.sites)
  const instants = useReducer(CateringStore.store, (s) => s.instants)

  const [status, setStatus] = React.useState<ScreenStatus>('loading')
  const [showPastInstants, setShowPastInstants] = React.useState(false)
  const [currentInstants, setCurrentInstants] = React.useState<Option[]>()
  const [pastInstants, setPastInstants] = React.useState<Option[]>()

  const displayedList = React.useMemo(() => {
    if (!showPastInstants && !!currentInstants && currentInstants.length > 0) {
      return currentInstants
    }
    if (showPastInstants && !!pastInstants && pastInstants.length > 0) {
      return pastInstants
    }
  }, [showPastInstants, currentInstants, pastInstants])

  React.useEffect(() => {
    api
      .getCateringInstants()
      .then(({ instants }) => {
        CateringStore.actions.setInstants(instants)
        setStatus('ok')
      })
      .catch((err) => {
        Logger.error(err)
        setStatus('error')
      })
  }, [])

  const groupInstantsByDate = (instants: InstantSimplified[], decreasing = false) => {
    const groups = Array.from(new Set(instants.map(({ date }) => date)))
    return groups.map((date) => ({
      title: capitalize(i18n.t('screens.meeting.catering.list.dayMonth', { date: new Date(date) })),
      data: instants
        .filter((instant) => instant.date === date)
        .sort((a, b) =>
          decreasing ? b.startingTime.localeCompare(a.startingTime) : a.startingTime.localeCompare(b.startingTime)
        ),
    }))
  }

  React.useEffect(() => {
    // On trie les instants par date et on sépare les instants passés de ceux à venir
    const sorted = instants
      .sort((a, b) => a.date.localeCompare(b.date))
      .reduce(
        (acc, cur) => {
          const now = new Date()
          const curEnd = formatDate(cur.date, cur.endingTime)
          if (isBefore(curEnd, now)) {
            // Réservation passée
            return { ...acc, past: [...acc.past, cur] }
          } else {
            // Réservation en cours ou à venir
            return { ...acc, current: [...acc.current, cur] }
          }
        },
        { current: [], past: [] } as { current: InstantSimplified[]; past: InstantSimplified[] }
      )
    setCurrentInstants(groupInstantsByDate(sorted.current))
    setPastInstants(groupInstantsByDate(sorted.past.reverse(), true))
  }, [instants])

  const renderItem = (item: InstantSimplified, index: number) => {
    const site = sites.find((s) => s.id.toString() === item.siteId)?.name

    const start = formatDate(item.date, item.startingTime)
    const end = formatDate(item.date, item.endingTime)

    const canceled = item.status === 'CANCELED'

    return (
      <li key={item.id}>
        <Item
          canceled={canceled}
          isFirst={index === 0}
          style={{ pointerEvents: 'auto', cursor: 'pointer' }}
          to={`/meeting/catering/${item.id}`}
          aria-label={i18n.t('accessibility.ariaLabels.register.goToReservation', { title: item.instantType.name })}>
          <Infos>
            <ItemDate>{i18n.t('screens.meeting.catering.list.timeSlot', { start, end })}</ItemDate>

            <ItemTitle canceled={canceled}>{item.instantType.name}</ItemTitle>
            {canceled && <ItemTitleStatus>{i18n.t('screens.meeting.catering.detail.canceled')}</ItemTitleStatus>}

            <Category
              icon="compass"
              label={
                !!site
                  ? i18n.t('screens.meeting.catering.list.place', { site, place: item.referentiel })
                  : item.referentiel
              }
              font="label"
              iconSize={16}
              iconColor={theme.colors.iconicGreyLighter}
            />
          </Infos>
          <Icon name="chevron_right" size={16} color={theme.colors.mauve} />
        </Item>
      </li>
    )
  }

  const renderSection = (section: { title: string; data: InstantSimplified[] }) => {
    if (!section || !section.data || section.data.length === 0) {
      return <></>
    }

    return (
      <SectionContainer key={section.title}>
        <SectionHeader
          aria-label={section.title}
          title={section.title}
          backgroundColor={theme.colors.middleLightGrey}
          borderColor={theme.colors.middleLightGrey}
        />
        <List>{section.data.map(renderItem)}</List>
      </SectionContainer>
    )
  }

  return (
    <div>
      <Line>
        {showPastInstants && (
          <BackButton
            onClick={() => setShowPastInstants(false)}
            aria-label={i18n.t('accessibility.ariaLabels.drawer.close')}>
            <Icon name="chevron_left" size={22} color={theme.colors.primaryDark} cursor="pointer" />
          </BackButton>
        )}

        <DrawerTitle>
          <label>{i18n.t(`screens.meeting.catering.list.${showPastInstants ? 'pastTitle' : 'currentTitle'}`)}</label>
        </DrawerTitle>
      </Line>

      {status === 'loading' ? (
        <Loader />
      ) : status === 'error' ? (
        <Empty>{i18n.t('screens.meeting.catering.list.error')}</Empty>
      ) : !displayedList ? (
        <Empty>{i18n.t('screens.meeting.catering.list.empty')}</Empty>
      ) : (
        <List>{displayedList.map(renderSection)}</List>
      )}

      {!showPastInstants && (
        <ButtonContainer>
          <Button
            label={i18n.t('screens.meeting.catering.list.showPast')}
            onClick={() => setShowPastInstants(true)}
            style="secondary"
          />
        </ButtonContainer>
      )}
    </div>
  )
}

export default MyInstantsList

const Line = styled('div')`
  flex-direction: row;
`

const BackButton = styled('div')`
  padding-left: 24px;
`

const DrawerTitle = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  padding: 0px 24px 24px;
  margin: 0px;
`

// SECTIONS LIST

const List = styled('ul')`
  list-style: none;
  padding: 0px;
  margin: 0px;
`

const SectionContainer = styled('li')`
  background-color: ${(props) => props.theme.colors.background};
`

// ITEMS LIST

const Item = styled(Link)<{ isFirst: boolean; canceled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  margin: 0px 24px;
  padding: 12px 0px;
  border-top: ${(props) => (props.isFirst ? 0 : 1)}px solid ${(props) => props.theme.colors.middleLightGrey};
  ${(props) => props.canceled && 'opacity: 0.8'};
`

const Infos = styled('div')`
  display: flex;
  flex: 1;
  text-align: start;
  flex-direction: column;
  justify-content: center;
  margin-left: 14px;
`

const Text = styled('p')`
  margin: 0px;
  padding: 0px;
`

const ItemDate = styled(Text)`
  ${(props) => props.theme.fonts.labelBold};
  color: ${(props) => props.theme.colors.mauve};
  line-height: 17px;
`

const ItemTitle = styled(Text)<{ canceled?: boolean }>`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => (props.canceled ? props.theme.colors.iconicGreyLighter : props.theme.colors.primaryText)};
  margin: 6px 0px ${(props) => (props.canceled ? 0 : 12)}px;
  line-height: 19px;
  ${(props) => props.canceled && 'text-decoration-line: line-through'};
  text-decoration-thickness: 1px;
`

const ItemTitleStatus = styled(Text)`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => props.theme.colors.iconicGrey};
  margin: 0 0 12px;
  line-height: 19px;
`

const ButtonContainer = styled('div')`
  padding: 32px 24px 24px;
  align-items: center;
`

const Empty = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.accentText};
  text-align: center;
  padding: 24px;
  max-width: 300px;
`
