import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Category from 'components/label/Category'

import useI18n from 'i18n/useI18n'
import config from 'core/src/config'

import { CONTENT_WIDTH, getDateString } from './utils'

interface Props {
  detail: AnimationDetail
  multipleDays: boolean
}

const DETAIL_RADIUS = 16

const AnimationContent = ({ detail, multipleDays }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const animationTimeLabel = React.useMemo(
    () =>
      !!detail && !!detail.slotFirstDate
        ? multipleDays && !!detail.slotLastDate
          ? `${getDateString(i18n, detail.slotFirstDate)} - ${getDateString(i18n, detail.slotLastDate)}`
          : getDateString(i18n, detail.slotFirstDate)
        : '',
    [detail, multipleDays]
  )

  return (
    <div>
      {!!detail.imageUrl && <Picture src={config.SERVER_PREFIX + detail.imageUrl} />}
      <InfoContainer>
        <ItemDate>{animationTimeLabel}</ItemDate>
        <ItemTitle>{detail.name}</ItemTitle>
        {!!detail.place && (
          <Category
            icon="compass"
            label={detail.place}
            font="body"
            iconSize={15}
            iconColor={theme.colors.iconicGreyLighter}
          />
        )}
        <Description>{detail.description}</Description>
      </InfoContainer>
    </div>
  )
}

export default AnimationContent

// ANIMATION INFO

const Picture = styled('img')`
  height: 295px;
  max-width: ${CONTENT_WIDTH}px;
  object-fit: cover;
  border-top-left-radius: ${DETAIL_RADIUS}px;
  border-top-right-radius: ${DETAIL_RADIUS}px;
`

const InfoContainer = styled('div')`
  background-color: ${(props) => props.theme.colors.contentBackground};
  padding: 16px 24px;
  max-width: ${CONTENT_WIDTH - 48}px;
  border-bottom-left-radius: ${DETAIL_RADIUS}px;
  border-bottom-right-radius: ${DETAIL_RADIUS}px;
`

const ItemDate = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.coral};
  margin: 0px;
`

const ItemTitle = styled('h2')`
  ${(props) => props.theme.fonts.h2};
  margin: 4px 0px 12px;
  letter-spacing: 0;
`

const Description = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primaryText};
  margin: 16px 0px 0px;
`
