import * as React from 'react'
import styled from 'theme/styled-components'
import theme from 'theme/Theme'

import Icons from 'components/icons/Icon'

import useI18n from 'i18n/useI18n'

interface Props {
  modes: InterCampusRoute[]
  mode?: InterCampusRoute
  onMode: (mode: InterCampusRoute) => void
}

interface MenuItem {
  name: string
  icon: IconName
  items: InterCampusRoute['transportMode'][]
}

const MENU: MenuItem[] = [
  { name: 'PIETON', icon: 'mobility_pieton', items: ['PIETON', 'PIETON_PMR'] },
  { name: 'VELO', icon: 'mobility_velo', items: ['VELO', 'VELO_ELECTRIQUE'] },
  { name: 'TRANSPORT', icon: 'mobility_bus', items: ['TRANSPORT_EN_COMMUN'] },
  {
    name: 'VOITURE',
    icon: 'mobility_voiture',
    items: ['VOITURE_DIESEL', 'VOITURE_ESSENCE', 'VOITURE_ELECTRIQUE', 'VOITURE_HYBRIDE'],
  },
]

const TransportModeSelect = ({ modes, mode, onMode }: Props) => {
  const i18n = useI18n()

  const renderItem = (item: typeof MENU[number]) => {
    const availables = modes.filter((m) => item.items.some((it) => it === m.transportMode))
    const selected = item.items.some((it) => it === mode?.transportMode)
    const disabled = availables.length === 0
    const transportType = selected ? mode || availables[0] : availables[0]

    return (
      <Item key={item.name} selected={selected} disabled={disabled} onClick={() => !disabled && onMode(transportType)}>
        <ItemIcon selected={selected}>
          <Icons
            name={item.icon}
            size={25}
            color={disabled ? theme.colors.unavailable : selected ? theme.colors.buttonText : theme.colors.primaryText}
          />
        </ItemIcon>

        <ItemTitle selected={selected}>
          {disabled
            ? i18n.t(`screens.intercampus.unavailable`)
            : i18n.t(`screens.intercampus.durationMin`, { minutes: transportType.duration.toFixed(0) })}
        </ItemTitle>

        {!disabled && (
          <Line>
            <Icons name="mobility_co2" size={12} color={theme.colors.co2} />
            <Co2>{i18n.t('screens.intercampus.carbone', { count: transportType.co2Emission.toFixed(0) })}</Co2>
          </Line>
        )}
      </Item>
    )
  }

  return <MainContainer>{MENU.map(renderItem)}</MainContainer>
}

export default TransportModeSelect

const MainContainer = styled('div')`
  background-color: ${(props) => props.theme.colors.background};
  padding: 10px;
  flex-direction: row;
  justify-content: center;

  ${(props) => props.theme.constants.shadow};
`

const Item = styled('div')<{ selected: boolean; disabled: boolean }>`
  flex-direction: column;
  background-color: ${(props) => (props.selected ? `${props.theme.colors.mobility}33` : 'transparent')};
  border: 1px solid ${(props) => (props.selected ? props.theme.colors.mobility : 'transparent')};
  border-radius: 10px;
  padding: 8px 12px;
  margin: 5px;
  align-items: center;

  :hover {
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
`

const ItemIcon = styled('div')<{ selected: boolean }>`
  background-color: ${(props) => (props.selected ? props.theme.colors.secondary : props.theme.colors.disable)};
  height: 45px;
  width: 45px;
  border-radius: 45px;
  align-items: center;
  justify-content: center;
`

const ItemTitle = styled('div')<{ selected: boolean }>`
  ${(props) => (props.selected ? props.theme.fonts.subtitleBold : props.theme.fonts.subtitle)};
  margin: 4px 0px;
`

const Line = styled('div')`
  flex-direction: row;
  align-items: center;
`

const Co2 = styled('div')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.iconicGreyLighter};
`
