import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'

interface Props {
  icon: IconName
  onClick: () => void
  color?: string
  size?: number
  iconColor?: string
  iconSize?: number
  disabled?: boolean
  square?: boolean
  backgroundColor?: string
  label?: string
  isBorder?: boolean
}

const Button = ({
  icon,
  onClick,
  color,
  size,
  iconColor,
  iconSize,
  disabled,
  square,
  backgroundColor,
  label,
  isBorder,
}: Props) => {
  const [theme] = useTheme()

  return (
    <Container
      role="button"
      aria-label={label}
      disabled={disabled}
      square={square}
      backgroundColor={backgroundColor}
      color={color}
      size={size || 50}
      onClick={onClick}
      isBorder={isBorder}>
      <Icon
        color={disabled ? theme.colors.iconicGrey : iconColor || theme.colors.primaryDark}
        name={icon}
        size={iconSize || 25}
        cursor={disabled ? 'default' : 'pointer'}
      />
    </Container>
  )
}

export default Button

const Container = styled('button')<{
  color?: string
  size: number
  disabled?: boolean
  square?: boolean
  backgroundColor?: string
  isBorder?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) => (props.square ? 5 : 25)}px;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;

  background-color: ${(props) =>
    props.disabled ? props.theme.colors.disable : props.backgroundColor || props.theme.colors.background};

  border: 1px solid
    ${(props) => (props.disabled ? props.theme.colors.disable : props.color || props.theme.colors.primaryText)};

  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
`
