import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from '../icons/Icon'

interface Props {
  style?: ButtonStyle
  label: string
  iconName: IconName
  iconSize?: number
  onClick: () => void
  ariaLabel?: string
  color?: string
  disabled?: boolean
  loading?: boolean
  width?: number
}

const ButtonIconLabel = ({
  style = 'secondary',
  label,
  iconName,
  iconSize = 20,
  onClick,
  ariaLabel,
  color,
  disabled,
  loading = false,
  width,
}: Props) => {
  const [Theme] = useTheme()

  const defaultColor = disabled ? Theme.colors.middleLightGrey : color
  const colors = {
    backgroundColor: !!defaultColor
      ? defaultColor
      : style === 'primary'
      ? Theme.colors.primaryDark
      : Theme.colors.background,
    borderColor: !!defaultColor
      ? defaultColor
      : style === 'small'
      ? Theme.colors.buttonDisabled
      : Theme.colors.primaryDark,
  }

  return (
    <Container
      onClick={!disabled ? onClick : undefined}
      tabIndex={0}
      aria-label={ariaLabel}
      disabled={disabled}
      buttonStyle={style}
      loading={loading}
      width={width}
      {...colors}>
      <Icon name={iconName} size={iconSize} />
      <Label disabled={disabled} buttonStyle={style}>
        {label}
      </Label>
    </Container>
  )
}

export default ButtonIconLabel

const Container = styled('button')<{
  buttonStyle: ButtonStyle
  backgroundColor: string
  borderColor?: string
  disabled?: boolean
  width?: number
  loading: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: ${(props) => (props.buttonStyle === 'small' ? 8 : 10)}px 8px;
  cursor: ${(props) => (props.loading ? 'wait' : props.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.borderColor};
  margin: 0;
  ${(props) => props.width && `width: ${props.width}px`};

  :hover {
    ${(props) =>
      !props.disabled &&
      (props.buttonStyle === 'small' ? `border-color: ${props.theme.colors.primaryDark}` : 'opacity: 0.8')};
  }
`

const Label = styled('p')<{ disabled?: boolean; buttonStyle: ButtonStyle }>`
  ${(props) => (props.buttonStyle === 'small' ? props.theme.fonts.subtitle : props.theme.fonts.body)};
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.buttonDisabled
      : props.buttonStyle === 'primary'
      ? props.theme.colors.buttonText
      : props.theme.colors.primaryDark};

  text-align: center;
  margin: 0 8px;
`
