import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import { breakpoints } from 'utils/breakpoints'
import { clearHtmlStyle, getHtmlStyle } from 'utils/stringUtils'
import { getDateLabel } from './utils'

import { Link } from 'react-router-dom'

// TODO : Utiliser ce composant sur les écrans Actualités, SafeAtWork, Infos Pratiques... (dans un prochain sprint)

interface Props {
  article: ArticleV4
}

export const MIN_WIDTH = 200
export const MAX_WIDTH = 600

const NewsHomeItem = ({ article }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  const dateLabel = getDateLabel(article, i18n)
  const locationLabel = !!article.location ? ` - ${article.location}` : ''

  const photo = article.photo && article.photo.sort((a, b) => a.position - b.position)[0]

  // Suppression du style présent dans le HTML
  const resume = React.useMemo(() => getHtmlStyle(Theme.fonts.bodyBold) + clearHtmlStyle(article.resume), [Theme])

  return (
    <ItemLink
      key={article.id}
      to={`/news/${article.id}`}
      aria-label={i18n.t('accessibility.ariaLabels.news.goToNews', { newsTitle: article.title })}>
      <ItemImage src={photo ? photo.url : require('core/src/assets/news_default.jpeg').default} alt="" />

      <ItemTag>{i18n.t(`tags.${article.tag}`)}</ItemTag>
      <ItemTitle>{article.title}</ItemTitle>
      {article.type === 'event' && (
        <ItemDate>
          {dateLabel}
          {locationLabel}
        </ItemDate>
      )}

      <Resume dangerouslySetInnerHTML={{ __html: resume }} />
    </ItemLink>
  )
}

export default NewsHomeItem

const ItemImage = styled('img')`
  object-fit: cover;
  user-select: none;
  border-radius: 12px;
  aspect-ratio: 4/3;
  height: auto;
  min-width: ${MIN_WIDTH}px;
  max-width: ${MAX_WIDTH}px;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: 100%;
  }
`

const ItemLink = styled(Link)`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-decoration: none;
  min-width: ${MIN_WIDTH}px;
  max-width: ${MAX_WIDTH}px;
  transition: all 0.1s ease-in-out;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: 100%;
  }

  :hover ${ItemImage} {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  :hover {
    transform: scale(1.02);
  }
`

const ItemTag = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.functionalities.news};
  margin-top: 16px;
`

const ItemTitle = styled('h2')`
  ${(props) => props.theme.fonts.h3Bold};
  max-height: 44px;
  margin: 0;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-wrap: break-word;
`

const ItemDate = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.functionalities.news};
  margin-top: 8px;
`

const Resume = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
  margin-top: 16px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-wrap: break-word;
`
