import { Configuration, PublicClientApplication, AccountInfo } from '@azure/msal-browser'
import conf from 'core/src/config'
import axios from 'axios'
import { UserSelf } from 'store/user/user'

import * as store from 'store/store'

const GRAPH_PREFIX = 'https://graph.microsoft.com/v1.0'

const config: Configuration = {
  auth: {
    clientId: conf.azure.clientId,
    authority: conf.azure.authority,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loadFrameTimeout: 300000,
  },
}

const resources = {
  graph: conf.azure.additionalScopes,
  server: [conf.azure.serverAPI],
  client: [`${conf.azure.clientId}`],
}

type Resources = keyof typeof resources

export const instance = new PublicClientApplication(config)

instance?.handleRedirectPromise()

const fullClear = () => {
  store.reset()
  sessionStorage.clear()

  Array.from(new Array(localStorage.length))
    .map((empty, idx) => localStorage.key(idx))
    .forEach((key) => {
      // clear every stored data except app persistence
      if (key && !key.startsWith('persist:')) {
        localStorage.removeItem(key)
      }
    })
}

const getActiveAccount = async (): Promise<AccountInfo | undefined> => {
  if (!instance) {
    throw new Error()
  }
  for (let i = 0; i < 10; i++) {
    if (instance.getAllAccounts()[0]) {
      return instance.getAllAccounts()[0]
    }
    await new Promise((resolve) => setTimeout(resolve, 200))
  }
  return
}

const MSALWebAuth: MSAL = {
  login: (resource: Resources = 'server') => {
    if (!instance) {
      throw new Error()
    }

    instance.loginRedirect({
      scopes: resources[resource],
      prompt: 'select_account',
      redirectUri: conf.azure.redirectUri,
    })
  },
  assureToken: (resource: Resources = 'server') => {
    if (!instance) {
      throw new Error()
    }
    return getActiveAccount().then((account) =>
      instance
        .acquireTokenSilent({ scopes: resources[resource], account, redirectUri: conf.azure.redirectUri })
        .then((tokens) => tokens.accessToken)
        .catch((err) => {
          if (err.errorCode !== 'no_account_error') {
            return instance
              .acquireTokenRedirect({
                scopes: resources[resource],
                account,
                redirectUri: conf.azure.redirectUri,
              })
              .then(() => MSALWebAuth.assureToken())
              .catch((err) => {
                fullClear()
                throw err
              })
          } else {
            fullClear()
          }

          throw err
        })
    )
  },
  me: () =>
    MSALWebAuth.assureToken('graph').then((token) =>
      execute<AzureMe>(
        '/me?$select=id,displayName,mail,userType,mobilePhone,businessPhones,jobTitle,surname,givenName,companyName,officeLocation,department',
        token,
        'GET'
      )
        .then((infos) => {
          const user: UserSelf = {
            id: infos.id,
            mail: infos.mail,
            displayName: infos.displayName,
            jobTitle: infos.jobTitle,
            companyName: infos.companyName,
            surname: infos.surname,
            givenName: infos.givenName,
            photoUrl: '/me/photos/120x120/$value',
            officeLocation: !!infos.officeLocation ? infos.officeLocation.split('/')[0] : null,
            department: infos.department,
            userType: infos.userType,
            businessPhones: infos.businessPhones,
            mobilePhone: infos.mobilePhone,
          }
          return user
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.error(err)
          MSALWebAuth.logout()
          throw new Error("Can't fetch user info from Azure")
        })
    ),
  logout: () => {
    if (!instance) {
      throw new Error()
    }
    fullClear()

    return Promise.resolve()
  },
  executeGraphRequest: (url, method, body, responseType) =>
    MSALWebAuth.assureToken('graph').then((token) => execute<any>(url, token, method, body, responseType)),
}

export default MSALWebAuth

const execute = <T>(
  url: string,
  token: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  body?: any,
  responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream'
): Promise<T> => {
  const headers: { [key: string]: string } = {
    'content-type': 'application/json',
    Authorization: `Bearer ${token}`,
    ConsistencyLevel: 'eventual',
  }

  return axios
    .request<T>({
      url: url.startsWith(GRAPH_PREFIX) ? url : GRAPH_PREFIX + url,
      method: method,
      headers,
      data: body ? JSON.stringify(body) : undefined,
      responseType,
    })
    .then((res) => {
      if (res.status >= 400) {
        throw new Error('Unable to query graph ' + url + ' : ' + res.status + ' / ' + res.statusText)
      }

      return res.data
    })
}
