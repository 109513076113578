import * as React from 'react'
import styled from 'theme/styled-components'

import TagFilter from './TagFilter'
import NewsItem from './NewsItem'
import Animator from 'components/animation/Animator'
import Loader from 'components/status/Loader'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main from 'components/main/Main'
import FiltersList from 'components/button/FiltersList'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as NewsStore from './store'
import * as SiteStore from 'site/store'

import api from './api'

import { breakpoints } from 'utils/breakpoints'

import IconButton from 'components/icons/IconButton'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

const FILTERS = ['all', 'info', 'event']

const TAGS = [
  'MOBILITY',
  'SECURITY',
  'ENVIRONMENT',
  'DIVERSITIES',
  'WELLBEING',
  'CAMPUS',
  'CIVICENGAGEMENT',
  'BIODIVERSITY',
  'RSE',
  'INTERNATIONAL',
  'URGENT',
  'NEWS',
  'LOCAL',
  'FUTURE',
  'SOCIETY',
  'BRANDS',
  'MEDICALSOCIAL',
  'CORPORATE',
  'FUNDATION',
  'WORKQUALITY',
  'SERVICE',
  'HERITAGE',
  'ARCHITECTURAL_PROJECT',
]

const NewsScreen = () => {
  const i18n = useI18n()

  const news = useReducer(NewsStore.store, (s) => s.news)
  const site = useReducer(SiteStore.store, (s) => s.site!)

  const [filter, setFilter] = React.useState(FILTERS[0])
  const [tagFilters, setTagFilters] = React.useState<string[]>()
  const [loading, setLoading] = React.useState(false)

  const items = React.useMemo(
    () =>
      news
        .filter((n) => filter === 'all' || n.type === filter)
        .filter((n) => !tagFilters || tagFilters.includes(n.tag)),
    [news, filter, tagFilters]
  )

  React.useEffect(() => {
    analytics.event(values.eventName.functionnality, {
      ecran: values.screens.news,
      site: site.name,
      type_utilisateur: analytics.typeUser(),
    })
  }, [])

  React.useEffect(() => {
    setLoading(true)

    api
      .all(site.id)
      .then((l) => NewsStore.actions.setNews(l.articles))
      .catch(NewsStore.actions.resetNews)
      .finally(() => setLoading(false))
  }, [site])

  if (loading) {
    return <Loader />
  }

  const renderItem = (article: ArticleV4) => <NewsItem key={article.id} article={article} />

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={i18n.t('screens.news.title')} />
        <Header>
          <Title>{i18n.t('screens.news.title')}</Title>
          <FiltersList screenName="news" filters={FILTERS} onClick={(f) => setFilter(f)} />
        </Header>

        <IconContainer>
          <IconButton
            name="options"
            onClick={() => TagFilter.open({ tags: TAGS, selected: tagFilters, onSubmit: setTagFilters })}
            ariaLabel={i18n.t('accessibility.ariaLabels.news.openModalFilterNews')}
            isFloating
          />
        </IconContainer>

        {items.length === 0 ? (
          <Error>{i18n.t('screens.news.empty')}</Error>
        ) : (
          <Animator isList gap={30}>
            {items.map(renderItem)}
          </Animator>
        )}
      </ScreenContainer>
    </Main>
  )
}

export default NewsScreen

const ScreenContainer = styled('div')`
  display: flex;
  padding: 50px 165px;
  z-index: 0;

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 50px 90px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const Header = styled('div')`
  flex-direction: row;
  margin-bottom: 30px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
  }
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h2Bold};
  color: ${(props) => props.theme.colors.primaryDark};
  flex: 1;
  margin: 0px;
`

const IconContainer = styled('div')`
  position: fixed;
  z-index: 1;
  bottom: 50px;
  right: 80px;
  @media only screen and (max-width: ${breakpoints.phone}px) {
    bottom: 30px;
    right: 30px;
  }
`

const Error = styled('p')`
  ${(props) => props.theme.fonts.body};
  text-align: center;
  margin: 50px;
`
