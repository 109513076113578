import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import { CONTENT_WIDTH, groupeSlotsByDay } from './utils'
import { capitalize } from 'utils/stringUtils'

interface Props {
  allSlots: Slot[]
  booked: Booking[]
  onSlotSelect: (slot?: Slot) => void
  onDaySelect: (day: string) => void
}

const CIRCLE_SIZE = 20
const POINT_SIZE = 15

const SlotsList = ({ allSlots, booked, onSlotSelect, onDaySelect }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const [selectedSlot, setSelectedSlot] = React.useState<Slot>()
  const [selectedDay, setSelectedDay] = React.useState<string>()

  const groupedSlots = React.useMemo(() => {
    if (!!allSlots) {
      return groupeSlotsByDay(allSlots)
    }
    return []
  }, [allSlots])

  const slotsDays = React.useMemo(() => (!!groupedSlots ? Object.keys(groupedSlots) : []), [groupedSlots])

  const slots = React.useMemo(() => {
    if (allSlots) {
      const filteredSlots = !!selectedDay ? allSlots.filter((slot) => slot.startDate.includes(selectedDay)) : allSlots
      return filteredSlots.sort((a, b) => a.startDate.localeCompare(b.startDate))
    }
    return []
  }, [allSlots, selectedDay])

  const multipleDays = React.useMemo(() => slotsDays.length > 1, [slotsDays])

  const selectDay = (day: string) => {
    setSelectedDay(day)
    onDaySelect(day)
    setSelectedSlot(undefined)
    onSlotSelect(undefined)
  }

  React.useEffect(() => {
    if (!!slotsDays && slotsDays.length > 0) {
      selectDay(slotsDays[0])
    }
  }, [slotsDays])

  const renderDay = (item: string) => {
    const date = new Date(item)
    const isSelected = item === selectedDay

    return (
      <li key={item}>
        <DayContainer
          selected={isSelected}
          onClick={() => selectDay(item)}
          style={{ backgroundColor: isSelected ? theme.colors.transparentRed : theme.colors.contentBackground }}>
          <DayNumber>{i18n.t('screens.register.detail.dayNumber', { date })}</DayNumber>
          <DayName>{capitalize(i18n.t('screens.register.detail.monthString', { date }).slice(0, 3) + '.')}</DayName>
          <DayName>{capitalize(i18n.t('screens.register.detail.dayString', { date }).slice(0, 3) + '.')}</DayName>
        </DayContainer>
      </li>
    )
  }

  const renderSlot = (item: Slot, index: number) => {
    const numberUser = item.numberUser || 0
    const limitUser = item.limitUser || 0
    const isAvailable = numberUser < limitUser
    const isSelected = item === selectedSlot
    const isAlreadyBooked = booked.some((booked) => booked.slotId === item.id)

    return (
      <SlotContainer
        key={item.id}
        isFirst={index === 0}
        isAlreadyBooked={isAlreadyBooked}
        onClick={() => {
          if (isAvailable && !isAlreadyBooked) {
            setSelectedSlot(item)
            onSlotSelect(item)
          }
        }}>
        <SlotInfo>
          <SlotTime>
            {i18n.t('screens.register.detail.timeSlot', {
              start: new Date(item.startDate),
              end: new Date(item.endDate),
            })}
          </SlotTime>
          <SlotOccupancy isAvailable={isAvailable}>
            {isAvailable
              ? i18n.t('screens.register.detail.available', { count: limitUser - numberUser })
              : i18n.t('screens.register.detail.full')}
          </SlotOccupancy>
        </SlotInfo>
        {isAvailable && !isAlreadyBooked && (
          <PointContainer selected={isSelected}>{isSelected && <Point />}</PointContainer>
        )}
      </SlotContainer>
    )
  }

  return (
    <div>
      {multipleDays && <DaysList>{slotsDays.map(renderDay)}</DaysList>}
      <List>{slots.map(renderSlot)}</List>
    </div>
  )
}

export default SlotsList

// DAYS LIST

const Text = styled('p')`
  margin: 0px;
  padding: 0px;
`

const DaysList = styled('ul')`
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin: 0px -32px;
  padding: 16px 24px 8px 56px;

  // Scrollbar non visible mais scroll autorisé :
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; // Internet Explorer, Edge
  scrollbar-width: 0px; // Firefox
  ::-webkit-scrollbar {
    display: none; // Chrome, Safari, Opera
  }
`

const DayContainer = styled('button')<{ selected: boolean }>`
  flex-direction: column;
  width: 52px;
  height: 70px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: ${(props) => (props.selected ? 2 : 1)}px solid
    ${(props) => (props.selected ? props.theme.colors.secondary : props.theme.colors.searchBar)};
  cursor: pointer;
`

const DayNumber = styled(Text)`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.primaryText};
`

const DayName = styled(Text)`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.primaryText};
`

// SLOTS LIST

const List = styled('ul')`
  list-style: none;
  margin: 0px;
  padding: 0px 0px 40px;
  max-width: ${CONTENT_WIDTH}px;
`

const SlotContainer = styled('div')<{ isFirst: boolean; isAlreadyBooked?: boolean }>`
  max-width: ${CONTENT_WIDTH - 48}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 24px;
  background-color: ${(props) =>
    props.isAlreadyBooked ? props.theme.colors.contentBackground : props.theme.colors.background};
  border: 0px solid ${(props) => props.theme.colors.middleLightGrey};
  border-top-width: ${(props) => (props.isFirst ? 0 : 0.5)}px;
`

const SlotInfo = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const SlotTime = styled(Text)`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.primaryText};
`

const SlotOccupancy = styled(Text)<{ isAvailable: boolean }>`
  ${(props) => props.theme.fonts.link};
  color: ${(props) => (props.isAvailable ? props.theme.colors.available : props.theme.colors.full)};
  text-decoration: none;
  margin-top: 4px;
`

const PointContainer = styled('div')<{ selected: boolean }>`
  height: ${CIRCLE_SIZE}px;
  width: ${CIRCLE_SIZE}px;
  border-radius: ${CIRCLE_SIZE}px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => (props.selected ? props.theme.colors.secondary : props.theme.colors.iconicGreyLighter)};
  background-color: ${(props) => props.theme.colors.background};
`

const Point = styled('div')`
  height: ${POINT_SIZE}px;
  width: ${POINT_SIZE}px;
  border-radius: ${POINT_SIZE}px;
  background-color: ${(props) => props.theme.colors.secondary};
`
