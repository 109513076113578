import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import RegisterList from './RegisterList'
import MyReservationsList from './MyReservationsList'

import Main from 'components/main/Main'
import Drawer from 'components/drawer/Drawer'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import FAB from 'components/button/FAB'

import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as SiteStore from 'site/store'

import { addDays, isSameDay } from 'date-fns'
import { capitalize } from 'utils/stringUtils'
import { breakpoints } from 'utils/breakpoints'
import { DEFAULT_WIDTH, fetchAnimations, fetchReservations } from './utils'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

const TODAY = new Date()
TODAY.setHours(0, 0, 0, 0)

const CURRENT_WEEK = addDays(TODAY, 1 - TODAY.getDay())

const RegisterScreen = () => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const site = useReducer(SiteStore.store, (s) => s.site)

  const [animationsStatus, setAnimationsStatus] = React.useState<ScreenStatus>('loading')
  const [reservationsStatus, setReservationsStatus] = React.useState<ScreenStatus>('loading')
  const [selectedWeek, setSelectedWeek] = React.useState<Date>(CURRENT_WEEK)

  const isCurrentWeek = React.useMemo(() => isSameDay(selectedWeek, CURRENT_WEEK), [selectedWeek])

  React.useEffect(() => {
    if (!!site) {
      analytics.event(values.eventName.functionnality, {
        ecran: values.screens.register,
        site: site.name,
        type_utilisateur: analytics.typeUser(),
      })
    }

    fetchAnimations(i18n.lang, site?.id).then(setAnimationsStatus)
    fetchReservations(i18n.lang).then(setReservationsStatus)
  }, [site, i18n.lang])

  return (
    <ScreenContainer>
      <TitleHelmet title={i18n.t('screens.register.title')} />

      <Main>
        <>
          <Header>
            <Title>{i18n.t('screens.register.title')}</Title>

            <DaySelector>
              <FAB
                icon="chevron_left"
                onClick={() => setSelectedWeek(addDays(selectedWeek, -7))}
                color={theme.colors.secondary}
                backgroundColor={theme.colors.secondary}
                iconColor={theme.colors.white}
                size={50}
                iconSize={20}
                disabled={isCurrentWeek}
              />

              <DateLabel>
                {capitalize(i18n.t('screens.register.week', { start: selectedWeek, end: addDays(selectedWeek, 6) }))}
              </DateLabel>

              <FAB
                icon="chevron_right"
                onClick={() => setSelectedWeek(addDays(selectedWeek, 7))}
                color={theme.colors.secondary}
                backgroundColor={theme.colors.secondary}
                iconColor={theme.colors.white}
                size={50}
                iconSize={20}
              />
            </DaySelector>
          </Header>

          <ListContainer>
            <RegisterList selectedWeek={selectedWeek} screenStatus={animationsStatus} />
          </ListContainer>
        </>
      </Main>

      <Drawer icon="star" closingWidth={1100}>
        <MyReservationsList screenStatus={reservationsStatus} />
      </Drawer>
    </ScreenContainer>
  )
}

export default RegisterScreen

const ScreenContainer = styled('div')`
  display: flex;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.contentBackground};

  @media only screen and (max-width: ${breakpoints.phone}px) {
    height: calc(100vh - 60px); // Hauteur du header = 60px
  }
`

const Header = styled('div')`
  padding: 50px 165px 25px;
  background-color: ${(props) => props.theme.colors.background};

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 50px 90px 25px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px 25px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px 25px;
  }
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h2Bold};
  color: ${(props) => props.theme.colors.darkGreyLighter};
  margin: 0px 0px 44px;
`

const DaySelector = styled('div')`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: ${DEFAULT_WIDTH}px;
`

const DateLabel = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  font-size: 18px;
  padding: 0px 16px;
  text-align: center;
`

const ListContainer = styled('div')`
  flex: 1;
  padding: 35px 165px;
  background-color: ${(props) => props.theme.colors.contentBackground};

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 35px 90px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 35px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 35px 35px;
  }
`
