import * as React from 'react'
import styled from 'theme/styled-components'
import Provider from 'theme/Provider'
import Theme from 'theme/Theme'

import Button from 'components/button/Button'
import Icon from 'components/icons/Icon'
import { SimpleAnimation } from 'components/animation/Animator'

import { breakpoints } from 'utils/breakpoints'
import useEscKeyPressed from 'utils/useEscKeyPressed'

import useI18n from 'i18n/useI18n'

import ReactDOM from 'react-dom'
import FocusLock from 'react-focus-lock'

import * as SitesStore from 'site/store'
import useReducer from 'store/useReducer'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  tags: string[]
  selected?: string[]
  onSubmit: (selected: string[]) => void
}

const Component = ({ tags, selected, onSubmit }: Props) => {
  const i18n = useI18n()

  const site = useReducer(SitesStore.store, (s) => s.site)

  const [current, setCurrent] = React.useState(selected || tags)

  useEscKeyPressed(TagFilter.close)

  const toggleItem = (item: string) => {
    const active = current.includes(item)

    if (active) {
      setCurrent(current.filter((c) => c !== item))
    } else {
      setCurrent([...current, item])
    }
  }

  if (!site) {
    return null
  }

  const save = () => {
    analytics.event(values.eventName.news, {
      action: values.actions.filter,
      site: site.name,
      type_utilisateur: analytics.typeUser(),
    })
    onSubmit(current)
    TagFilter.close()
  }

  const renderItem = (item: string) => {
    const active = current.includes(item)

    return (
      <li key={item}>
        <CheckButton
          onClick={() => toggleItem(item)}
          aria-label={i18n.t(`accessibility.ariaLabels.news.filterButton${active ? 'Activated' : ''}`, {
            filter: i18n.t(`tags.${item}`),
          })}>
          <CheckBox active={active}>{active && <Icon name="check" color={Theme.colors.white} size={12} />}</CheckBox>
          <CheckText>{i18n.t(`tags.${item}`)}</CheckText>
        </CheckButton>
      </li>
    )
  }

  return (
    <SimpleAnimation animation="fade">
      <MainContainer>
        <Back />

        <FocusLock autoFocus={true} returnFocus>
          <Container
            id="TagFilter"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modalFilterSelectionHeading"
            tabIndex={-1}>
            <Title id="modalFilterSelectionHeading">{i18n.t('screens.news.tag.filter')}</Title>

            <TagList>{tags.map(renderItem)}</TagList>

            <ButtonContainer>
              <Button label={i18n.t('screens.news.tag.apply')} onClick={save} />
            </ButtonContainer>
            <ButtonContainer>
              <Button label={i18n.t('screens.news.tag.reset')} onClick={() => setCurrent(tags)} style="secondary" />
            </ButtonContainer>
          </Container>
        </FocusLock>
      </MainContainer>
    </SimpleAnimation>
  )
}

const MainContainer = styled('div')`
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  opacity: 1;

  -webkit-animation: fadeIn 0.3s linear;
  animation: fadeIn 0.3s linear;
  z-index: 1;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Back = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.colors.backgroundModal};
`

const Container = styled('div')`
  width: 400px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  padding: 40px;
  @media only screen and (max-width: ${breakpoints.small}px) {
    width: 240px;
  }
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px 0px 20px;

  font-weight: 700;
`

const CheckButton = styled('button')`
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-bottom: 15px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.background};
  border: 0px;
`

const CheckBox = styled('div')<{ active?: boolean }>`
  height: 18px;
  width: 18px;
  border: 1px solid ${(props) => (props.active ? props.theme.colors.secondary : props.theme.colors.secondaryText)};
  background-color: ${(props) => (props.active ? props.theme.colors.secondary : 'transparent')};
  border-radius: 2px;
  align-items: center;
  justify-content: center;
`

const CheckText = styled('p')`
  ${(props) => props.theme.fonts.labelBold};
  color: ${(props) => props.theme.colors.secondaryText};
  margin: 0px 0px 0px 15px;

  font-weight: 700;
  font-family: 'Georgia';
`

const ButtonContainer = styled('div')`
  margin-top: 15px;
`

const TagList = styled('ul')`
  list-style: none;
  padding: 0;
  max-height: 325px;
  overflow: auto;
`

let modalRoot: HTMLElement | null

const TagFilter = {
  open: (props: Props) => {
    modalRoot = document.getElementById('portal_root')

    if (modalRoot) {
      ReactDOM.render(
        <Provider>
          <Component {...props} />
        </Provider>,
        modalRoot
      )
    }
  },
  close: () => {
    if (modalRoot) {
      ReactDOM.unmountComponentAtNode(modalRoot)
    }
  },
}

export default TagFilter
