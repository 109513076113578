import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

export interface ButtonProps {
  style?: ButtonStyle
  label: string
  onClick?: () => void
  ariaLabel?: string
  color?: string
  disabled?: boolean
  loading?: boolean
  width?: number
}

const MIN_BUTTON_WIDTH = 200

const Button = ({
  style = 'primary',
  label,
  onClick,
  ariaLabel,
  color,
  disabled,
  loading = false,
  width,
}: ButtonProps) => {
  const [Theme] = useTheme()

  const defaultColor = disabled ? Theme.colors.middleLightGrey : color
  const colors = {
    backgroundColor: !!defaultColor
      ? defaultColor
      : style === 'primary'
      ? Theme.colors.primaryDark
      : Theme.colors.background,
    borderColor: !!defaultColor
      ? defaultColor
      : style === 'small'
      ? Theme.colors.buttonDisabled
      : Theme.colors.primaryDark,
  }

  return (
    <Container
      onClick={!disabled && !!onClick ? onClick : undefined}
      tabIndex={0}
      aria-label={ariaLabel}
      disabled={disabled}
      buttonStyle={style}
      loading={loading}
      width={width}
      {...colors}>
      <Label disabled={disabled} buttonStyle={style}>
        {label}
      </Label>
    </Container>
  )
}

export default Button

const Container = styled('button')<{
  buttonStyle: ButtonStyle
  backgroundColor: string
  borderColor?: string
  disabled?: boolean
  width?: number
  loading: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${MIN_BUTTON_WIDTH}px;
  border-radius: 50px;
  padding: ${(props) => (props.buttonStyle === 'small' ? 8 : 10)}px 16px;
  cursor: ${(props) => (props.loading ? 'wait' : props.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.borderColor};
  margin: 0;
  ${(props) => props.width && `width: ${props.width}px`};

  :hover {
    ${(props) =>
      !props.disabled &&
      (props.buttonStyle === 'small' ? `border-color: ${props.theme.colors.primaryDark}` : 'opacity: 0.8')};
  }
`

const Label = styled('p')<{ disabled?: boolean; buttonStyle: ButtonStyle }>`
  ${(props) => (props.buttonStyle === 'small' ? props.theme.fonts.subtitleBold : props.theme.fonts.bodyBold)};
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.buttonDisabled
      : props.buttonStyle === 'primary'
      ? props.theme.colors.buttonText
      : props.theme.colors.primaryDark};

  text-align: center;
  margin: 0px;
`
