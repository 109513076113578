import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'
import Alert from 'components/alert/Alert'
import Animators from 'components/animation/Animator'
import ModalLoader from 'components/status/ModalLoader'
import EditBookingModal from 'components/modal/EditBookingModal'

import { Link } from 'react-router-dom'

import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as BookingStore from './store'
import * as SitesStore from 'site/store'

import api from './api'

import { startOfDay } from 'date-fns'
import { I18n } from 'i18n/i18n'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

type MeetingAndReferentiel = {
  meeting: Meeting
  referentiel: ReferentielData
}
type MeetingsByDate = {
  date: Date
  data: MeetingAndReferentiel[]
}

interface Values {
  start: Date
  end: Date
  range: boolean
  period: 'morning' | 'afternoon' | 'allDay'
}
interface Props {
  form: Values
}

const getResaName = (i18n: I18n, referentiel: Referentiel) =>
  (referentiel.data && referentiel.data[`libelle_${i18n.lang}`]) || referentiel.email

const MyBookingList = ({ form }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  const site = useReducer(SitesStore.store, (s) => s.site)
  const reservations = useReducer(BookingStore.store, (s) => s.reservations || [])

  const meetingsByDate = React.useMemo(
    () =>
      reservations.reduce((acc, cur) => {
        cur.meetings?.forEach((m) => {
          const date = startOfDay(new Date(m.startDateTime))
          let ms = acc.find((a) => a.date === date)
          if (!ms) {
            ms = {
              date,
              data: [],
            }
            acc.push(ms)
          }
          ms.data.push({
            meeting: m,
            referentiel: cur.referentiel!,
          })
        })
        return acc
      }, [] as MeetingsByDate[]),
    [reservations]
  )

  const loadBookings = () => {
    api.getReservations().then((res) => BookingStore.actions.setReservations(res.schedules || []))
  }

  React.useEffect(() => {
    loadBookings()
  }, [])

  const deleteMeeting = (meeting: MeetingAndReferentiel) => {
    if (!meeting.meeting.id) {
      return Promise.reject()
    }

    return api.deskBooking.deleteReservation(meeting.meeting.id, meeting.referentiel.email!)
  }

  if (!site) {
    return null
  }

  const onDelete = (meeting: MeetingAndReferentiel) => {
    Alert.open({
      title: i18n.t('screens.meeting.booking.alert.deleteBooking'),
      description: i18n.t('screens.meeting.booking.alert.deleteBookingMessage', {
        room: getResaName(i18n, meeting.referentiel),
        start: new Date(meeting.meeting.startDateTime),
        end: new Date(meeting.meeting.endDateTime),
      }),
      buttons: [
        {
          label: i18n.t('common.yes'),
          onClick: () => {
            ModalLoader.open()

            deleteMeeting(meeting)
              .then(() => {
                analytics.event(values.eventName.booking, {
                  action: values.actions.deleteBooking,
                  site: site.name,
                  type_utilisateur: analytics.typeUser(),
                  espace: meeting.referentiel.room || meeting.referentiel.id,
                })
                Alert.open({
                  title: i18n.t('screens.meeting.booking.alert.deleteSuccess'),
                  description: '',
                })
              })
              .catch(() =>
                Alert.open({
                  title: i18n.t('common.error'),
                  description: i18n.t('screens.meeting.booking.alert.errorMessage'),
                })
              )
              .finally(() => {
                ModalLoader.close()
                loadBookings()
              })
          },
        },
        {
          label: i18n.t('common.no'),
          onClick: Alert.close,
        },
      ],
    })
  }

  const onUpdate = (meeting: MeetingAndReferentiel) => {
    analytics.event(values.eventName.booking, {
      action: values.actions.editBooking,
      site: site.name,
      type_utilisateur: analytics.typeUser(),
      espace: meeting.referentiel.id,
    })
    EditBookingModal.open({
      title: i18n.t('screens.guest.visitInfo.startTime.label'),
      room: meeting,
    })
  }
  const goToMap = (referentiel: ReferentielData) =>
    analytics.event(values.eventName.booking, {
      action: values.actions.redirectMap,
      site: site.name,
      type_utilisateur: analytics.typeUser(),
      espace: referentiel.room || referentiel.id,
    })

  const renderMeeting = (resa: MeetingAndReferentiel, index: number, list: MeetingAndReferentiel[]) => {
    const locationInfo = resa.referentiel
    const name = (locationInfo.data && locationInfo.data[`libelle_${i18n.lang}`]) || locationInfo.email
    const meeting = resa.meeting
    return (
      <React.Fragment key={meeting.id}>
        <MeetingContainer>
          <MeetingHeader>
            <Location>{name}</Location>
            <Timespan>
              {i18n.t('screens.meeting.booking.timespan', {
                start: new Date(`${meeting.startDateTime}`),
                end: new Date(`${meeting.endDateTime}`),
              })}
            </Timespan>
          </MeetingHeader>
          {(!!locationInfo.referencialType ||
            !!locationInfo.site ||
            !!locationInfo.building ||
            !!locationInfo.floor) && (
            <Infos>
              {locationInfo.referencialType && <Info>{locationInfo.referencialType}</Info>}
              {locationInfo.site && <Info>{locationInfo.site}</Info>}
              {locationInfo.floor && (
                <Info>{i18n.t('screens.meeting.booking.floor', { floor: locationInfo.floor })}</Info>
              )}
              {locationInfo.building && <Info>{locationInfo.building}</Info>}
            </Infos>
          )}
          <Buttons>
            {locationInfo.room && (
              <ItemLink to={`/map?location=${locationInfo.room}`} onClick={() => goToMap(locationInfo)}>
                <Icon name="map" size={20} color={Theme.colors.secondary} />
                <Label>{i18n.t('screens.meeting.booking.actions.localize')}</Label>
              </ItemLink>
            )}
            <ItemButton onClick={() => onUpdate(resa)}>
              <Icon name="pencil" size={20} color={Theme.colors.secondary} cursor="pointer" />
              <Label>{i18n.t('screens.meeting.booking.actions.edit')}</Label>
            </ItemButton>
            <ItemButton onClick={() => onDelete(resa)}>
              <Icon name="trash" size={20} color={Theme.colors.secondary} cursor="pointer" />
              <Label>{i18n.t('screens.meeting.booking.actions.delete')}</Label>
            </ItemButton>
          </Buttons>
        </MeetingContainer>
        {index !== list.length - 1 && <Separator />}
      </React.Fragment>
    )
  }

  const renderMeetingsByDate = (meetings: MeetingsByDate) => (
    <DateContainer key={meetings.date.getTime()}>
      <DateValue>{i18n.t('screens.meeting.booking.picker.value.start', { date: meetings.date })}</DateValue>
      {meetings.data.map(renderMeeting)}
    </DateContainer>
  )

  return (
    <Container>
      <Title>{i18n.t('screens.meeting.booking.myBookings')}</Title>
      {meetingsByDate.length > 0 ? (
        <Animators isList>{meetingsByDate.map(renderMeetingsByDate)}</Animators>
      ) : (
        <NoMeeting>{i18n.t('screens.meeting.booking.noBooking')}</NoMeeting>
      )}
    </Container>
  )
}

export default MyBookingList

const Container = styled('div')``

const Title = styled('h1')`
  padding: 0px 22px 10px 22px;
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px;
`

const DateContainer = styled('div')``

const DateValue = styled('h2')`
  ${(props) => props.theme.fonts.bodyBold};
  padding: 6px 24px;
  border: 0px;
  border-bottom-width: 1px;
  border-top-width: 1px;
  color: ${(props) => props.theme.colors.primary};
  margin: 0px;
`

const MeetingContainer = styled('div')`
  padding: 20px 22px;
`

const MeetingHeader = styled('div')`
  ${(props) => props.theme.fonts.subtitleBold};
  flex-direction: row;
`

const Separator = styled('div')`
  height: 1px;
  margin: 0px 22px;
  background-color: ${(props) => props.theme.colors.middleLightGrey};
`

const Location = styled('p')`
  flex: 1;
  margin: 0px 20px 0px 0px;
  ${(props) => props.theme.fonts.subtitleBold};
  font-family: 'Georgia';
`

const Timespan = styled('p')`
  ${(props) => props.theme.fonts.subtitleBold};
  font-family: 'Georgia';
  color: ${(props) => props.theme.colors.primary};
  margin: 0px;
`

const Buttons = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 14px;
`

const ItemButton = styled('button')`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.theme.colors.background};
  border: 0px;
  padding: unset;
`

const ItemLink = styled(Link)`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
`

const Label = styled('span')`
  margin-left: 10px;
  ${(props) => props.theme.fonts.label}
`

const NoMeeting = styled('p')`
  padding: 0px 22px;
  margin: 0px;
  ${(props) => props.theme.fonts.subtitle}
`

const Infos = styled('div')`
  margin-top: 9px;
`

const Info = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 0px;
`
