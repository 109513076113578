import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'

interface Props {
  label: string
  icon: IconName
  color?: string
  iconColor?: string
  onClick?: () => void
  disabled?: boolean
  focusable?: boolean
  ariaLabel?: string
}

const IconLabel = ({ label, icon, color, iconColor, onClick, disabled, focusable = false, ariaLabel }: Props) => {
  const [Theme] = useTheme()

  return (
    <Container
      disabled={disabled}
      active={!disabled && !!onClick}
      onClick={disabled ? undefined : onClick}
      tabIndex={focusable ? 0 : -1}
      aria-label={ariaLabel}>
      <Icon name={icon} color={iconColor || Theme.colors.primary} size={16} />
      <Label color={color || Theme.colors.primaryText}>{label}</Label>
    </Container>
  )
}

export default IconLabel

const Container = styled('button')<{ active: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  cursor: ${(props) => (props.active ? 'pointer' : 'default')};
  background-color: ${(props) => props.theme.colors.background};
  border: 0px;
`

const Label = styled('p')<{ color: string }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.color};
  margin: 0px 0px 0px 12px;
`
