import React from 'react'
import styled from 'theme'

import { breakpoints } from 'utils/breakpoints'

import { Link } from 'react-router-dom'

interface Props {
  imageSource: string
  title: string
  description?: string
  isInternalLink?: boolean
  link?: string
  isImageIcon?: boolean
  onClick?: () => void
}

const IMAGE_SIZE = 130

type ContainerType = {
  isInternalLink?: boolean
  link?: string
  onClick?: () => void
  isImageIcon?: boolean
}

const Container = ({ link, isInternalLink, onClick, isImageIcon, children }: React.PropsWithChildren<ContainerType>) =>
  isInternalLink ? (
    <LinkContainer to={link || ''} onClick={onClick}>
      {children}
    </LinkContainer>
  ) : (
    <AContainer href={link} target="_blank" rel="noreferrer" onClick={onClick}>
      {children}
    </AContainer>
  )

const ListItemLink = ({ description, imageSource, link, isInternalLink, title, isImageIcon, onClick }: Props) => {
  return (
    <Container isInternalLink={isInternalLink} link={link} onClick={onClick}>
      <ImageContainer>
        <ItemImage src={imageSource} />
      </ImageContainer>
      <ItemInfos>
        <ItemTitle>{title}</ItemTitle>
        {!!description && <ItemResume>{description}</ItemResume>}
      </ItemInfos>
    </Container>
  )
}

export default ListItemLink

const LinkContainer = styled(Link)`
  padding: 16px;
  display: flex;
  flex-direction: row;

  border-radius: 15px;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  align-items: center;
  text-decoration: none;

  @media only screen and (max-width: ${breakpoints.small}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const AContainer = styled('a')`
  padding: 16px;
  display: flex;
  flex-direction: row;

  border-radius: 15px;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  align-items: center;
  text-decoration: none;

  @media only screen and (max-width: ${breakpoints.small}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const ImageContainer = styled('div')`
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: ${IMAGE_SIZE}px;
  width: ${IMAGE_SIZE}px;

  @media only screen and (max-width: ${breakpoints.small}px) {
    width: 100%;
    max-width: 100%;
    align-items: center;
  }
`

const ItemImage = styled('img')`
  object-fit: cover;
  user-select: none;
  height: ${IMAGE_SIZE}px;
  width: ${IMAGE_SIZE}px;
`

const ItemInfos = styled('div')`
  padding: 0 24px;
  flex: 1;
  flex-direction: column;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 0 20px;
  }
`

const ItemTitle = styled('h2')`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.secondary};
  margin: 0px;
`

const ItemResume = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 15px 0px 0px;
  font-size: 18px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-wrap: break-word;
`
