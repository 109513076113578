import * as React from 'react'

import useI18n from 'i18n/useI18n'

import * as SiteStore from 'site/store'
import useReducer from 'store/useReducer'

import PickerPortal from 'components/picker/PickerModal'
import PickerQuantity from 'components/picker/PickerQuantityModal'
import Loader from 'components/status/Loader'
import AlertPortal from 'components/alert/Alert'
import DatePickerPortal from 'components/picker/DatePickerModal'
import TimePickerPortal from 'components/picker/TimePickerModal'
import FormField from 'components/picker/FormField'
import WarningLabel from 'components/label/WarningLabel'
import Button from 'components/button/Button'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import api from './api'

import Logger from 'utils/Logger'

import { FormikConfig, useFormik } from 'formik'
import * as Yup from 'yup'

import { addBusinessDays, addYears, isSameDay, differenceInMinutes } from 'date-fns'
import {
  formatDate,
  formatTimeString,
  getArticlesPrice,
  getHourFromString,
  getDateFromHour,
  isInstantTooLate,
  isInvalidPeriod,
  isInactivePeriod,
} from './utils'
import { addFloat, multiplyFloat } from 'utils/math'

export interface FormValues
  extends Omit<
    InstantToCreate,
    'date' | 'startingTime' | 'endingTime' | 'instantTypeId' | 'additionalArticlesId' | 'referentielReference'
  > {
  date: Date
  startingTime: Date
  endingTime: Date
  instantType: InstantType
  additionalArticles: FoodSimplified[]
  cateringPlace: CateringPlace
  costCenter?: CostCenter
}

interface Props {
  mode: 'CREATE' | 'DETAIL' | 'UPDATE'
  bookedInstant?: InstantDetailed
  onSubmit?: FormikConfig<Partial<FormValues>>['onSubmit']
  onValuesChange?: (values: Partial<FormValues>) => void
}

export const TEXT_SEPARATOR = ' • '
const MAX_GUESTS = 1000
const MAX_COMMENT = 1000

export const MAX_HOUR = 14
const MIN_DURATION = 5

const CateringForm = ({ mode, bookedInstant, onSubmit, onValuesChange }: Props) => {
  const NOW = new Date()
  const DEFAULT_DATE = addBusinessDays(NOW, 2)

  const i18n = useI18n()
  const [theme] = useTheme()

  const currentSite = useReducer(SiteStore.store, (s) => s.site)

  const [screenStatus, setScreenStatus] = React.useState<ScreenStatus>('loading')

  const [cateringPlaces, setCateringPlaces] = React.useState<CateringPlace[]>([])
  const [instantTypes, setInstantTypes] = React.useState<InstantType[]>([])
  const [foods, setFoods] = React.useState<Food[]>([])
  const [siteParameters, setSiteParameters] = React.useState<SiteParameter>()
  const [costCenters, setCostCenters] = React.useState<CostCenter[]>([])

  React.useEffect(() => {
    const siteId = !!bookedInstant ? parseInt(bookedInstant.siteId) : currentSite?.id

    if (siteId) {
      setScreenStatus('loading')
      Promise.all([
        api.getCateringInstantTypes(siteId),
        api.getCateringPlaces(siteId),
        api.getCateringFood(siteId),
        api.getSiteParameter(siteId),
        api.getCateringCostCenters(siteId),
      ])
        .then(([{ instantTypes }, { catering_places }, { foods }, siteParameters, { costCenters }]) => {
          setInstantTypes(instantTypes)
          setCateringPlaces(catering_places)
          setFoods(foods)
          setSiteParameters(siteParameters)
          setCostCenters(costCenters)
          setScreenStatus('ok')
        })
        .catch((err) => {
          Logger.error(err)
          setScreenStatus('error')
        })
    }
  }, [currentSite?.id, bookedInstant])

  const formValidationSchema = (costType: 'COST_CENTER' | 'CUSTOM_COST_CENTER') =>
    Yup.object().shape<Partial<Record<keyof Partial<FormValues>, any>>>({
      numberGuests: Yup.number()
        .required(
          !!maxGuestUser
            ? i18n.t('screens.meeting.catering.form.fieldError.guestsMinMax', { min: minGuestUser, max: maxGuestUser })
            : i18n.t('screens.meeting.catering.form.fieldError.guestsMin', { count: minGuestUser })
        )
        .min(minGuestUser, i18n.t('screens.meeting.catering.form.fieldError.guestsMin', { count: minGuestUser }))
        .max(
          maxGuestUser || MAX_GUESTS,
          i18n.t('screens.meeting.catering.form.fieldError.guestsMax', { count: maxGuestUser || MAX_GUESTS })
        ),
      comment: Yup.string().max(
        MAX_COMMENT,
        i18n.t('screens.meeting.catering.form.fieldError.commentMax', { max: MAX_COMMENT })
      ),
      instantType: Yup.object()
        .required(i18n.t('screens.meeting.catering.form.fieldError.required'))
        .test(
          'isInstantTooLate',
          i18n.t('screens.meeting.catering.form.fieldContent.pastDeadline'),
          (instantType) =>
            !instantType ||
            !isInstantTooLate(values.date || NOW, ((instantType as unknown) as InstantType).timeAheadDay)
        ),
      date: Yup.date()
        .required(i18n.t('screens.meeting.catering.form.fieldError.required'))
        .test(
          'isWeekend',
          i18n.t('screens.meeting.catering.form.fieldError.dateWeekend'),
          (value) => value?.getDay() !== 0 && value?.getDay() !== 6
        ),
      cateringPlace: Yup.object().required(i18n.t('screens.meeting.catering.form.fieldError.required')),
      costCenter: Yup.object().test(
        costType,
        i18n.t('screens.meeting.catering.form.fieldError.required'),
        (costCenter) => !!costCenter || costType !== 'COST_CENTER'
      ),
      costCenterId: Yup.string().test(
        costType,
        i18n.t('screens.meeting.catering.form.fieldError.required'),
        (costCenterId) => !!costCenterId || costType !== 'CUSTOM_COST_CENTER'
      ),
      startingTime: Yup.string().required(i18n.t('screens.meeting.catering.form.fieldError.required')),
      endingTime: Yup.string().required(i18n.t('screens.meeting.catering.form.fieldError.required')),
    })

  const initialValues: Partial<FormValues> = {
    siteId: currentSite?.id.toString(),
    date: DEFAULT_DATE,
  }

  const {
    handleSubmit,
    values,
    setValues,
    setFieldValue,
    handleChange,
    handleBlur,
    errors,
    validateField,
    isValid,
  } = useFormik<Partial<FormValues>>({
    initialValues,
    onSubmit: !!onSubmit ? onSubmit : Logger.info,
    validationSchema: () => formValidationSchema(siteParameters?.costType || 'COST_CENTER'),
    validateOnBlur: true,
  })

  React.useEffect(() => {
    if (!!values && !!bookedInstant && !!onValuesChange) {
      // Mise à jour des valeurs si on modifie un instant déjà réservé
      onValuesChange(values)
    }
  }, [values])

  React.useEffect(() => {
    if (!!bookedInstant && !!instantTypes && !!cateringPlaces) {
      // Initialisation des valeurs d'après celles de l'instant déjà réservé
      setValues({
        date: formatDate(bookedInstant.date, bookedInstant.startingTime),
        startingTime: formatDate(bookedInstant.date, bookedInstant.startingTime),
        endingTime: formatDate(bookedInstant.date, bookedInstant.endingTime),
        instantType: instantTypes.find((it) => it.id === bookedInstant.instantType.id),
        cateringPlace: cateringPlaces.find((cp) => cp.referentielName === bookedInstant.referentiel),
        numberGuests: bookedInstant.numberGuests,
        comment: bookedInstant.comment,
        costCenter: bookedInstant.costCenter,
        costCenterId: bookedInstant.customCostCenter,
        additionalArticles: bookedInstant.additionalArticles || [],
      })
    }
  }, [bookedInstant, mode, instantTypes, cateringPlaces])

  const openValidationModal = () =>
    AlertPortal.open({
      title: i18n.t('screens.meeting.catering.form.confirmOrder'),
      description: '',
      buttons: [
        {
          label: i18n.t('common.validate'),
          onClick: () => {
            AlertPortal.close()
            handleSubmit()
          },
        },
        { label: i18n.t('common.cancel'), onClick: AlertPortal.close, style: 'secondary' },
      ],
    })

  const minGuestUser = values.instantType ? values.instantType.minUser : 1
  const maxGuestUser = values.instantType && values.instantType.maxUser

  // Affichage différent sur le campus Seine 62
  const altCostCenterStyle = currentSite?.id === 3
  const isCustomCostCenter = siteParameters?.costType === 'CUSTOM_COST_CENTER'

  const costCenterLabel = React.useMemo(
    () => i18n.t(`screens.meeting.catering.form.fieldTitle.${altCostCenterStyle ? 'costCenter' : 'customCostCenter'}`),
    [altCostCenterStyle]
  )

  const dateError = React.useMemo(() => {
    if (!!values.date && !!currentSite && !!siteParameters && !!siteParameters.inactivityPeriods) {
      const inactivePeriod = isInactivePeriod(values.date, siteParameters.inactivityPeriods)

      if (!!inactivePeriod) {
        return i18n.t('screens.meeting.catering.form.fieldError.inactivityPeriod', {
          site: currentSite.name,
          start: new Date(inactivePeriod.startDate),
          end: new Date(inactivePeriod.endDate),
        })
      }
    }
  }, [!!currentSite, siteParameters, values.date])

  const timeError = React.useMemo(() => {
    if (!!values.startingTime) {
      // Vérification de la plage horaire autorisée
      const allowedStart = values.instantType?.allowedStartingTime
      const allowedEnd = values.instantType?.allowedEndingTime
      const isSlotTime = !!allowedStart && !!allowedEnd

      const slotLabel = !!isSlotTime
        ? i18n.t('screens.meeting.catering.form.fieldError.timeSlot', {
            start: getDateFromHour(allowedStart),
            end: getDateFromHour(allowedEnd),
          })
        : undefined

      return !!isSlotTime &&
        (formatTimeString(values.startingTime).localeCompare(allowedStart.slice(0, 5)) < 0 ||
          formatTimeString(values.startingTime).localeCompare(allowedEnd.slice(0, 5)) > 0)
        ? slotLabel
        : undefined
    }
  }, [values.startingTime, values.endingTime, values.instantType])

  const durationError = React.useMemo(() => {
    if (!!values.startingTime) {
      // Vérification de la durée de l'instant
      return !!values.endingTime && differenceInMinutes(values.endingTime, values.startingTime) < MIN_DURATION
        ? i18n.t('screens.meeting.catering.form.fieldError.minDuration', { count: MIN_DURATION })
        : undefined
    }
  }, [values.startingTime, values.endingTime])

  const articlesPrice = React.useMemo(() => getArticlesPrice(foods, values.additionalArticles), [
    values.additionalArticles,
    foods,
  ])

  const price = React.useMemo(() => {
    if (!!values.instantType && !!values.numberGuests) {
      // Prix de l'instant selon le nombre de participants (sans les articles additionnels)
      const guestsInstantPrice = multiplyFloat(values.numberGuests, values.instantType.price)
      // Prix total (en comptant les articles additionnels)
      return addFloat(guestsInstantPrice, articlesPrice)
    }
    return articlesPrice
  }, [values.numberGuests, values.instantType, articlesPrice])

  const PriceField = () => (
    <FieldContainer>
      <FieldTitle htmlFor="price">{i18n.t('screens.meeting.catering.form.fieldTitle.price')}</FieldTitle>
      <PriceFieldContent id="price">
        {i18n.t('screens.meeting.catering.form.fieldContent.price', { price })}
      </PriceFieldContent>
    </FieldContainer>
  )

  const openPlacesPicker = () => {
    PickerPortal.open({
      title: i18n.t('screens.meeting.catering.form.fieldPlaceholder.cateringPlace'),
      selected: values.cateringPlace?.referentielReference,
      data: cateringPlaces
        .filter((p) => !!p.referentielName && !!p.referentielReference)
        .sort((p1, p2) => p1.referentielName.localeCompare(p2.referentielName))
        .map((p) => ({
          label: p.referentielName!,
          value: p.referentielReference!,
          customRender: (selected) => (
            <TypeOptionContainer>
              <TypeOptionTitle>{p.referentielName}</TypeOptionTitle>
              <RadioContainer selected={selected}>{selected && <RadioContent />}</RadioContainer>
            </TypeOptionContainer>
          ),
        })),
      onItemSelected: (item) =>
        setFieldValue(
          'cateringPlace',
          cateringPlaces.find((p) => p.referentielReference === item)
        ),
      withSearch: true,
      buttonColor: theme.colors.primaryDark,
    })
  }

  const openInstantPicker = () => {
    PickerPortal.open({
      title: i18n.t('screens.meeting.catering.form.fieldPlaceholder.instantType'),
      selected: values.instantType?.id,
      data: instantTypes.map((p) => {
        const isTooLate = !!values.date && isInstantTooLate(values.date, p.timeAheadDay)
        const isInvalid = !!values.startingTime && !!values.endingTime && isInvalidPeriod(values.startingTime, p)

        return {
          label: p.name!,
          value: p.id!,
          customRender: (selected) => (
            <TypeOptionContainer>
              <TypeOptionInfosContainer>
                <TypeOptionTitle>
                  {i18n.t('screens.meeting.catering.form.fieldContent.instantTypeTitle', {
                    name: p.name,
                    price: p.price,
                  })}
                </TypeOptionTitle>
                <TypeOptionDescription>{p.description}</TypeOptionDescription>

                <TypeOptionInfosLine>
                  <TypeOptionInfos>
                    {i18n.t('screens.meeting.catering.form.fieldContent.instantTypeMinUser', { minUser: p.minUser }) +
                      TEXT_SEPARATOR}
                  </TypeOptionInfos>
                  {p.maxUser && (
                    <TypeOptionInfos>
                      {i18n.t('screens.meeting.catering.form.fieldContent.instantTypeMaxUser', { maxUser: p.maxUser }) +
                        TEXT_SEPARATOR}
                    </TypeOptionInfos>
                  )}
                  {isTooLate && (
                    <TypeOptionInfos warning={true}>
                      {i18n.t('screens.meeting.catering.form.fieldContent.pastDeadline')}
                    </TypeOptionInfos>
                  )}
                  <TypeOptionInfos>
                    {i18n.t(
                      `screens.meeting.catering.form.fieldContent.instantTypeTimeAhead${isTooLate ? '_past' : ''}`,
                      { count: p.timeAheadDay }
                    )}
                  </TypeOptionInfos>
                </TypeOptionInfosLine>

                {isInvalid ? (
                  <TypeOptionInfosLine>
                    <TypeOptionInfos warning={true}>
                      {i18n.t('screens.meeting.catering.form.fieldContent.invalidPeriod')}
                    </TypeOptionInfos>
                    <TypeOptionInfos>
                      {i18n.t('screens.meeting.catering.form.fieldContent.invalidPeriodDetail', {
                        minHour: getHourFromString(p.allowedStartingTime),
                        maxHour: getHourFromString(p.allowedEndingTime),
                      })}
                    </TypeOptionInfos>
                  </TypeOptionInfosLine>
                ) : (
                  <TypeOptionInfosLine>
                    <TypeOptionInfos>
                      {i18n.t('screens.meeting.catering.form.fieldContent.validPeriod', {
                        minHour: getHourFromString(p.allowedStartingTime),
                        maxHour: getHourFromString(p.allowedEndingTime),
                      })}
                    </TypeOptionInfos>
                  </TypeOptionInfosLine>
                )}
              </TypeOptionInfosContainer>
              {!isTooLate && !isInvalid && (
                <RadioContainer selected={selected}>{selected && <RadioContent />}</RadioContainer>
              )}
            </TypeOptionContainer>
          ),
          disabled: isTooLate || isInvalid,
        }
      }),
      onItemSelected: (item) =>
        setFieldValue(
          'instantType',
          instantTypes.find((p) => p.id === item)
        ).then(() => {
          validateField('numberGuests')
        }),
      buttonColor: theme.colors.primaryDark,
      warning: i18n.t('screens.meeting.catering.form.fieldContent.instantTypeWarning'),
    })
  }

  const openAdditionalArticlesPicker = () => {
    PickerQuantity.open({
      data: foods.map((f) => ({
        minQuantity: f.minQuantity,
        initialQuantity: values.additionalArticles?.find((a) => a.id === f.id)?.quantity,
        label: i18n.t('screens.meeting.catering.form.fieldContent.foodTitle', { name: f.name, price: f.price }),
        description: [
          f.description,
          f.minQuantity &&
            i18n.t('screens.meeting.catering.form.fieldContent.foodMinQuantity', { quantity: f.minQuantity }),
        ]
          .filter(Boolean)
          .join(' • '),
        value: f.id,
      })),
      onValidate: (quantities) =>
        setFieldValue(
          'additionalArticles',
          foods
            .map((f) => ({ id: f.id, name: f.name, quantity: quantities[f.id] } as FoodSimplified))
            .filter((f) => !!f.quantity)
        ),
      title: i18n.t('screens.meeting.catering.form.fieldTitle.additionalArticles'),
    })
  }

  const openDatePicker = () => {
    DatePickerPortal.open({
      date: values.date || DEFAULT_DATE,
      onDateSelected: (d) => {
        setFieldValue('date', d).then(() => {
          validateField('instantType')
        })
      },
      title: i18n.t('screens.meeting.catering.form.fieldTitle.date'),
      min: NOW,
      max: addYears(NOW, 1),
      buttonColor: theme.colors.primaryDark,
    })
  }

  const openTimePicker = () => {
    TimePickerPortal.open({
      min: !!values.date && isSameDay(NOW, values.date) ? NOW : undefined,
      title: i18n.t('screens.meeting.catering.form.fieldTitle.time'),
      onTimeSelected: (start, end) => {
        setFieldValue('startingTime', start, true).then(() => {
          if (end) {
            setFieldValue('endingTime', end, true)
          }
        })
      },
      time: values.startingTime,
      timeEnd: values.endingTime,
      withEnd: true,
      buttonColor: theme.colors.primaryDark,
    })
  }

  const openCostCentersPicker = () => {
    PickerPortal.open({
      title: i18n.t('screens.meeting.catering.form.fieldPlaceholder.costCenter'),
      selected: values.costCenter?.id || values.costCenterId,
      data: costCenters
        // On supprime les doublons de la liste
        .filter(
          (c1, i) => !!c1.id && costCenters.findIndex((c2) => c2.id === c1.id || c2.lorealId === c1.lorealId) === i
        )
        .map((p) => ({
          label: p.lorealId,
          value: p.id,
          customRender: (selected) => (
            <TypeOptionContainer>
              <TypeOptionTitle>{p.lorealId}</TypeOptionTitle>
              <RadioContainer selected={selected}>{selected && <RadioContent />}</RadioContainer>
            </TypeOptionContainer>
          ),
        })),
      onItemSelected: (item) =>
        setFieldValue(
          'costCenter',
          costCenters.find((c) => c.id === item)
        ),
      buttonColor: theme.colors.primaryDark,
      withSearch: true,
    })
  }

  const onChange = (e: React.FocusEvent<any>) => {
    if (mode !== 'DETAIL') {
      handleChange(e)
    }
  }

  const onBlur = (e: React.FocusEvent<any>) => {
    if (mode !== 'DETAIL') {
      handleBlur(e)
    }
  }

  if (screenStatus === 'loading') {
    return <Loader />
  }

  if (screenStatus === 'error') {
    return <ErrorText>{i18n.t('screens.meeting.catering.form.fetchError')}</ErrorText>
  }

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      {mode === 'CREATE' && (
        <>
          <Indication>{i18n.t('screens.meeting.catering.form.pleaseFill')}</Indication>

          <WarningLabel
            icon="warning_round"
            description={i18n.t('screens.meeting.catering.form.warning')}
            color={theme.colors.warning}
          />

          <Line>
            <FormField
              fieldType="picker"
              error={errors.cateringPlace}
              disabled={!cateringPlaces.length}
              disabledStyle={!cateringPlaces.length}
              inputId="cateringPlace"
              onClick={openPlacesPicker}
              placeholder={i18n.t('screens.meeting.catering.form.fieldPlaceholder.cateringPlace')}
              title={i18n.t('screens.meeting.catering.form.fieldTitle.cateringPlace')}
              value={values.cateringPlace?.referentielName}
            />
            <FormField
              fieldType="picker"
              error={errors.date || dateError}
              inputId="date"
              onClick={openDatePicker}
              placeholder={i18n.t('screens.meeting.catering.form.fieldPlaceholder.date')}
              title={i18n.t('screens.meeting.catering.form.fieldTitle.date')}
              value={
                values.date
                  ? i18n.t('screens.meeting.catering.form.fieldContent.date', { date: values.date })
                  : undefined
              }
            />
            <FormField
              fieldType="picker"
              inputId="time"
              error={errors.startingTime || errors.endingTime || timeError || durationError}
              onClick={openTimePicker}
              placeholder={i18n.t('screens.meeting.catering.form.fieldPlaceholder.time')}
              title={i18n.t('screens.meeting.catering.form.fieldTitle.time')}
              value={
                !!values.startingTime && !!values.endingTime
                  ? i18n.t('screens.meeting.catering.form.fieldContent.time', {
                      startingTime: values.startingTime,
                      endingTime: values.endingTime,
                    })
                  : undefined
              }
            />
          </Line>

          <Separator />
        </>
      )}

      {mode !== 'CREATE' && (
        <>
          <Line>
            <FormField
              fieldType="smallInput"
              error={!isValid && !!values.instantType ? errors.numberGuests : undefined}
              inputId="numberGuests"
              title={i18n.t('screens.meeting.catering.form.fieldTitle.guests')}
              type="number"
              value={values.numberGuests || 0}
              min={minGuestUser}
              max={maxGuestUser}
              onChange={onChange}
              onBlur={onBlur}
              disabled={mode === 'DETAIL'}
            />
          </Line>
          <Separator />
        </>
      )}

      <Line>
        <FormField
          fieldType="picker"
          error={errors.instantType}
          inputId="instantType"
          onClick={openInstantPicker}
          placeholder={i18n.t('screens.meeting.catering.form.fieldPlaceholder.instantType')}
          title={i18n.t('screens.meeting.catering.form.fieldTitle.instantType')}
          value={values.instantType?.name || bookedInstant?.instantType.name}
          disabled={mode === 'DETAIL' || !instantTypes.length}
          disabledStyle={!instantTypes.length}
        />
        {mode === 'CREATE' && (
          <FormField
            fieldType="smallInput"
            error={!isValid && !!values.instantType ? errors.numberGuests : undefined}
            inputId="numberGuests"
            title={i18n.t('screens.meeting.catering.form.fieldTitle.guests')}
            type="number"
            value={values.numberGuests || 0}
            min={minGuestUser}
            max={maxGuestUser}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
        {(mode === 'DETAIL'
          ? !!bookedInstant?.additionalArticles && bookedInstant.additionalArticles.length > 0
          : siteParameters?.orderMore) && (
          <FormField
            fieldType="picker"
            inputId="additionalArticles"
            onClick={openAdditionalArticlesPicker}
            placeholder={i18n.t('screens.meeting.catering.form.fieldPlaceholder.additionalArticles')}
            title={i18n.t('screens.meeting.catering.form.fieldTitle.additionalArticles')}
            value={values.additionalArticles
              ?.map((a) => i18n.t('screens.meeting.catering.form.fieldContent.additionalArticle', a))
              .join(', ')}
            disabled={mode === 'DETAIL' || !foods.length}
            disabledStyle={!foods.length}
          />
        )}
      </Line>

      <Separator />

      <Line>
        {isCustomCostCenter ? (
          <CustomFieldContainer>
            <FormField
              fieldType="smallInput"
              error={errors.costCenterId}
              inputId="costCenterId"
              title={costCenterLabel}
              value={values.costCenterId}
              onChange={onChange}
              onBlur={onBlur}
              disabled={mode === 'DETAIL'}
            />
            {altCostCenterStyle && !values.costCenterId && (
              <FieldHint>{i18n.t('screens.meeting.catering.form.fieldTitle.costCenterHint')}</FieldHint>
            )}
          </CustomFieldContainer>
        ) : (
          <FormField
            fieldType="picker"
            error={errors.costCenter}
            inputId="costCenter"
            title={i18n.t('screens.meeting.catering.form.fieldTitle.costCenter')}
            value={values.costCenter?.lorealId}
            placeholder={i18n.t('screens.meeting.catering.form.fieldPlaceholder.costCenter')}
            onClick={openCostCentersPicker}
            disabled={mode === 'DETAIL'}
          />
        )}
        <PriceField />
      </Line>

      {(mode !== 'DETAIL' || !!bookedInstant?.comment) && (
        <Line>
          <FormField
            fieldType="bigInput"
            inputId="comment"
            title={i18n.t('screens.meeting.catering.form.fieldTitle.comment')}
            value={values.comment}
            placeholder={i18n.t('screens.meeting.catering.form.fieldPlaceholder.comment')}
            onChange={onChange}
            onBlur={onBlur}
            disabled={mode === 'DETAIL'}
          />
        </Line>
      )}

      {mode === 'CREATE' && (
        <Button
          label={i18n.t('screens.meeting.catering.form.submit')}
          onClick={openValidationModal}
          disabled={!isValid || !values.numberGuests || !!dateError || !!timeError || !!durationError}
          color={theme.colors.primaryDark}
        />
      )}
    </Form>
  )
}

export default CateringForm

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Indication = styled.p`
  ${(props) => props.theme.fonts.body};
  margin: 0 0 20px;
`

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const FieldTitle = styled.label`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.mauve};
  margin: 0;
`

const Line = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px 40px;
  margin: 24px 0px;
`

const Separator = styled.hr`
  height: 1px;
  background-color: ${(props) => props.theme.colors.middleLightGrey};
  border: 0;
  width: 100%;
`

const TypeOptionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  text-align: start;
  border-bottom: 1px solid ${(props) => props.theme.colors.middleLightGrey};
  padding-bottom: 20px;
`

const TypeOptionInfosContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
`

const TypeOptionInfosLine = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 4px;
`

const TypeOptionTitle = styled.h5`
  flex: 1;
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => props.theme.colors.primaryDark};
  margin: 0;
`

const TypeOptionDescription = styled.p`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.iconicGreyLighter};
  margin: 0 0 10px;
`

const TypeOptionInfos = styled.p<{ warning?: boolean }>`
  ${(props) => (props.warning ? props.theme.fonts.labelBold : props.theme.fonts.label)};
  color: ${(props) => (props.warning ? props.theme.colors.warning : props.theme.colors.primaryDark)};
  margin: 0;
`

const RadioContainer = styled.div<{ selected: boolean }>`
  height: 18px;
  width: 18px;
  border-radius: 18px;
  border: 1px solid ${(props) => (props.selected ? props.theme.colors.mauve : props.theme.colors.primaryDark)};
  align-items: center;
  justify-content: center;
`

const RadioContent = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.mauve};
`

const ErrorText = styled.p`
  ${(props) => props.theme.fonts.bodyBold};
  margin: 0;
`

const PriceFieldContent = styled.p`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.primaryDark};
  margin: 0;
`

const CustomFieldContainer = styled.div`
  flex-direction: column;
  max-width: 300px;
`

const FieldHint = styled.p`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.primaryText};
  margin: 20px 0 0;
`
