import * as React from 'react'
import styled from 'theme/styled-components'

import Map from './Map'

import api from './api'

import config from 'core/src/config'
import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as SiteStore from 'site/store'
import * as ReferentielStore from 'store/referentiel/referentiel'

import useWebsocket from 'utils/useWebsocket'

import TitleHelmet from 'components/titleHelmet/TitleHelmet'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  location?: string
}

const MapScreen = ({ location }: Props) => {
  const i18n = useI18n()
  const site = useReducer(SiteStore.store, (s) => s.site!)
  const pois = useReducer(ReferentielStore.referentielStore, (s) => s.referentiels)
  const occupancies = useReducer(ReferentielStore.occupancyStore, (s) => s.occupancies)

  const [newOccupancies, setNewOccupancies] = React.useState<OccupancyList>({})

  const onOccupancy = (d: { type: string; data: OccupancyStatus[]; delta?: boolean }) => {
    if (d.type === 'occupancy') {
      // On ne traite que les changements d'occupation du site (si précisé)
      const siteData = !!site.name ? d.data.filter((occ) => occ.siteName === site.name) : d.data

      if (siteData.length > 0) {
        // delta=true : on ajoute les nouvelles données sans écraser les anciennes
        // delta=false : on écrase les anciennes données avec les nouvelles
        const lastOccupancies = d.delta ? ReferentielStore.occupancyStore.getState().occupancies : {}
        const newOccupancies = siteData.reduce((acc, cur) => ({ ...acc, [cur.visioglobeId]: cur }), {} as OccupancyList)

        ReferentielStore.actions.setOccupancies({ ...lastOccupancies, ...newOccupancies })
        setNewOccupancies(newOccupancies)
      }
    }
  }

  const [, , connect, disconnect] = useWebsocket<OccupancyStatus[]>(config.WSS_URL, onOccupancy)

  React.useEffect(() => {
    // start websocket
    connect()

    analytics.event(values.eventName.functionnality, {
      ecran: values.screens.map,
      site: site.name,
      type_utilisateur: analytics.typeUser(),
    })

    // stop websocket
    return () => disconnect()
  }, [])

  React.useEffect(() => {
    // get pois
    api.all(site.id).then((l) => ReferentielStore.actions.setReferentiels(l.referentiels))
  }, [site])

  const mapFNC = site.functionalities.find((f) => f.type === 'MAP')

  if (!mapFNC || !mapFNC.param || !mapFNC.param.mapHash) {
    return null
  }

  return (
    <MainContainer>
      <TitleHelmet title={i18n.t('screens.map.title')} />
      <Map
        key={site.id}
        hash={mapFNC.param.mapHash}
        pois={pois}
        occupancies={occupancies}
        newOccupancies={newOccupancies}
        location={location}
      />
    </MainContainer>
  )
}

export default MapScreen

const MainContainer = styled('div')`
  flex: 1;
  justify-content: center;
`
