import * as React from 'react'
import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'
interface Option {
  id: string
  name: string
}

interface Props {
  title: string
  onItemSelected: (item: Option) => void
  items: Option[]
  isBuildingSelector?: boolean
}

const MapSelector = ({ items, onItemSelected, title, isBuildingSelector = false }: Props) => {
  const [open, setOpen] = React.useState(false)
  const i18n = useI18n()

  const onScreenClick = () => {
    setOpen(false)
  }

  const onItem = (item: Option) => {
    onItemSelected(item)
    setOpen(false)
  }

  React.useEffect(() => {
    if (open) {
      window.addEventListener('click', onScreenClick)
      return () => window.removeEventListener('click', onScreenClick)
    }
  }, [open])

  const getAriaLabel = () => {
    const startString = 'accessibility.ariaLabels.map.'
    if (isBuildingSelector) {
      return title === i18n.t('screens.map.buildings')
        ? i18n.t(`${startString}selectorBuildingNotSelected`)
        : i18n.t(`${startString}selectorBuildingSelected`, { building: title })
    } else {
      return i18n.t(`${startString}selectorFloor`, { floor: title })
    }
  }

  return (
    <Select>
      <>
        {open && (
          <Selection>
            {items.map((it) => (
              <Item key={it.id} onClick={() => onItem(it)}>
                {it.name}
              </Item>
            ))}
          </Selection>
        )}
        <Selector onClick={() => setOpen(!open)} aria-label={getAriaLabel()}>
          {title}
        </Selector>
      </>
    </Select>
  )
}

export default MapSelector

const Select = styled('div')`
  width: 200px;
  margin-right: 25px;
  flex-direction: column;
`

const Selector = styled('button')`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.buttonText};
  padding: 13px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.17);
  border-radius: 45px;
  border: 0px;
  background-color: ${(props) => props.theme.colors.secondaryLighter};
  text-align: center;
  cursor: pointer;
`

const Selection = styled('div')`
  width: 200px;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 25px;
  max-height: 500px;
  overflow: auto;
  margin-bottom: 25px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.17);
`

const Item = styled('button')`
  ${(props) => props.theme.fonts.h3Bold};
  text-align: center;
  padding: 15px;
  cursor: pointer;
  border: 0px;
  background-color: ${(props) => props.theme.colors.background};
`
