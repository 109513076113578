import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import Category from 'components/label/Category'
// import Drawer from 'components/root/Drawer'
import Icon from 'components/icons/Icon'
import Loader from 'components/status/Loader'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as RegisterStore from './store'

import config from 'core/src/config'

import { DEFAULT_WIDTH, getDateString, getDay } from './utils'
import { addDays, isBefore, isAfter } from 'date-fns'

import { Link } from 'react-router-dom'

interface Props {
  selectedWeek: Date
  screenStatus: ScreenStatus
}

const RegisterList = ({ selectedWeek, screenStatus }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const animations = useReducer(RegisterStore.store, (s) => s.animations)

  const [status, setStatus] = React.useState<ScreenStatus>(screenStatus)

  const filteredAnimations = React.useMemo(
    () =>
      animations
        .filter(
          (anim) =>
            isBefore(new Date(anim.publicationDate), addDays(selectedWeek, 6)) &&
            isAfter(new Date(anim.slotLastDate), selectedWeek)
        )
        .sort((a, b) => a.slotFirstDate.localeCompare(b.slotFirstDate)),
    [animations, selectedWeek]
  )

  React.useEffect(() => {
    setStatus(screenStatus)
  }, [screenStatus])

  const renderItem = (item: AnimationSimple) => {
    const multipleDays = getDay(item.slotFirstDate) !== getDay(item.slotLastDate)

    const dateLabel = multipleDays
      ? `${getDateString(i18n, item.slotFirstDate)} - ${getDateString(i18n, item.slotLastDate)}`
      : getDateString(i18n, item.slotFirstDate)

    return (
      <ItemLink
        key={item.id}
        to={`/register/${item.id}`}
        aria-label={i18n.t('accessibility.ariaLabels.register.goToAnimation', { title: item.name })}>
        <Picture src={config.SERVER_PREFIX + item.imageUrl} />
        <Infos>
          <ItemDate>{dateLabel}</ItemDate>
          <ItemTitle>{item.name}</ItemTitle>

          {!!item.place && (
            <Category
              icon="compass"
              label={item.place}
              font="subtitle"
              iconSize={16}
              iconColor={theme.colors.iconicGreyLighter}
            />
          )}
        </Infos>

        <Icon name="chevron_right" size={16} color={theme.colors.primaryDark} />
      </ItemLink>
    )
  }

  if (status === 'loading') {
    return <Loader />
  }

  if (status === 'error') {
    return (
      <EmptyContainer>
        <Empty>{i18n.t('screens.register.error')}</Empty>
      </EmptyContainer>
    )
  }

  if (!filteredAnimations || filteredAnimations.length === 0) {
    return (
      <EmptyContainer>
        <Empty>{i18n.t('screens.register.empty')}</Empty>
      </EmptyContainer>
    )
  }

  return <List>{filteredAnimations.map(renderItem)}</List>
}

export default RegisterList

const List = styled('ul')`
  flex-direction: column;
  list-style: none;
  margin: 0px;
  padding: 0px;
`

const ItemLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  margin-bottom: 16px;
  padding: 0px 14px 0px 0px;
  max-width: ${DEFAULT_WIDTH}px;
  border-radius: 15px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.15);
`

const Picture = styled('img')`
  object-fit: cover;
  height: 98px;
  width: 98px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: ${(props) => props.theme.colors.disable};
`

const Infos = styled('div')`
  display: flex;
  flex: 1;
  text-align: start;
  flex-direction: column;
  justify-content: center;
  margin-left: 14px;
`

const Text = styled('p')`
  margin: 0px;
  padding: 0px;
`

const ItemDate = styled(Text)`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => props.theme.colors.coral};
  line-height: 17px;
`

const ItemTitle = styled(Text)`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.primaryText};
  margin: 6px 0px 12px;
  line-height: 19px;
`

const EmptyContainer = styled('div')`
  display: flex;
  flex: 1;
  padding: 24px 0px;
  align-items: center;
  justify-content: center;
`

const Empty = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.accentText};
  text-align: center;
`
